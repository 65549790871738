import { AssessmentSubCategory } from "./assessment-sub-category";
import { AuditFields } from "./common";

export class AssessmentCategory {
    categoryId: number;
    area: string;
    categoryName: string;
    displayOrder: number;
    isActive: boolean;
    isAdd: boolean;
    subCategoriesList: AssessmentSubCategory[];
}
