import {
    Directive, ElementRef, Input,
    OnChanges
} from '@angular/core';

@Directive({ selector: '[imgPreview]' })
export class ImagePreviewDirective implements OnChanges {

    @Input()
    media: any;

    constructor(private el: ElementRef) { }

    ngOnChanges() {

        let reader = new FileReader();
        let el = this.el;
        reader.onloadend = function (e) {
            el.nativeElement.src = reader.result;
        };

        if (this.media) {
            return reader.readAsDataURL(this.media);
        }

    }
}
