import { Injectable } from '@angular/core';
import { TreeviewItem, TreeviewSelection, DefaultTreeviewI18n } from 'ngx-treeview';

@Injectable()
export class DropdownTreeviewSelectI18n extends DefaultTreeviewI18n {
    private internalSelectedItem: TreeviewItem;

    set selectedItem(value: TreeviewItem) {
        this.internalSelectedItem = value;
    }

    get selectedItem(): TreeviewItem {
        return this.internalSelectedItem;
    }

    // getText(selection: TreeviewSelection): string {
    //     if (this.internalSelectedItem && this.internalSelectedItem.value && this.internalSelectedItem.value.feeHead && this.internalSelectedItem.value.feeTerm) {
    //         return `${this.internalSelectedItem.value.academicYear} - ${this.internalSelectedItem.value.feeHead.headName} - ${this.internalSelectedItem.value.feeTerm.termName}`
    //     }
    //     return 'Please select';
    // }

    getText(): string {
        if (this.internalSelectedItem) {
            let result = '';
            if (this.internalSelectedItem.value && this.internalSelectedItem.value.feeHead && this.internalSelectedItem.value.feeTerm) {
                result = `${this.internalSelectedItem.value.feeHead.headName} - ${this.internalSelectedItem.value.feeTerm.termName}`
            }
            return result;
        }
        return 'Please Select';
    }
}