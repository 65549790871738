import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/public_api';

export const CommonConstant = {
    token: 'token',
    guestToken: 'guestToken',
    orgCode: 'orgCode',
    school: 'school',
    employeeName: 'employeeName',
    employeeId: 'employeeId',
    user: 'user',
    imageFallback: 'assets/images/fallback.png',
    appDateFormat: 'DD MMM YYYY',
    serverDateFormat: 'YYYY-MM-DD',
    prjDateFormat: 'DD-MMM-YYYY',
    serverTimeFormat: 'hh:mm:ss',
    appTimeFormat: 'hh:mm A',
    defaultPageSize: 10,
    reportDownloadPageSize: 1000,
    appName: 'appName',
    studentProfileId: 'studentProfileId',
    studentName: 'studentName',
    userName:'userName',
    schoolName: 'schoolName'
};

export const DefaultDateOption: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    dateInputFormat: 'DD-MMM-YYYY',
    rangeInputFormat: 'DD-MMM-YYYY',
    showWeekNumbers: false,
    adaptivePosition: true,
};

export const Genders: string[] = ['Male', 'Female'];
export const Prefixs: string[] = ['Mr.', 'Ms.', 'Mrs.', 'Miss.'];
export const BloodGroups: string[] = [
    'A - Positive',
    'A - Negative',
    'B - Positive',
    'B - Negative',
    'AB - Positive',
    'AB - Negative',
    'O - Positive',
    'O - Negative',
];
export const LeaveRequestStatus: string[] = ['Pending', 'Approved', 'Rejected'];
export const employeeDepartments: string[] = ['Teaching Staff', 'Management'];
export const LeaveTypes: string[] = ['Earned', 'Casual', 'Sick'];
export const app = [] = ['Spectrum'];

export const CalendarColors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
    green: {
        primary: '#28a745',
        secondary: '#FDF1BA',
    },
    darkblue: {
        primary: '#17a2b8',
        secondary: '#FDF1BA',
    },
};
