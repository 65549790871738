import { MemberBasicInfo } from './member';
import { AuditFields } from './common';

export class Subscription {
    id: number;
    member: MemberBasicInfo;
    features: string;
    planName: string;
    libraryCode: string;
    subscriptionDate: string;
    subscriptionAmount: number;
    startDate: string;
    expiryDate: string;
    subscriptionStatus: string;
    recordStatus: string;
    auditFields: AuditFields;

    // Index
    index: number;
}
