<div class="card text-center justify-content-center shaodw-lg card-1 border-0 bg-white px-sm-2" *ngIf="templateData">
    <div class="card-body show" [ngClass]="templateData.active ? 'selected' : ''" (click)="onCardClick()">
        <div class="justify-content-between px-3 text-center a">
            <div class="col-auto mr-sm-2 mx-1 py-0 text-center">
                <div class="flex-row">
                    <div class="col">
                        <div class="pic">
                            <img class="irc_mut img-fluid" *ngIf="!templateData.previewUrl"
                                src="https://i.imgur.com/bGCqROr.png" width="100" height="500">
                            <embed [src]="templateData.previewUrl" *ngIf="templateData.previewUrl"
                                style="width: 100%;height: 500px" class="irc_mut img-fluid" type="application/pdf">
                        </div>
                        <p>{{templateData.displayName}}</p>
                        <div class="download-btn">
                            <button *ngIf="templateData.previewUrl" class="app-btn outline"
                                (click)="download.emit(templateData?.downloadUrl || templateData?.previewUrlForTs || templateData?.previewUrl)">
                                <i class="fa fa-download mr-1" aria-hidden="true"></i>
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>