import { Address, AuditFields } from "./common";

export class Employee {
    employeeProfileId?: number;
    namePrefix: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName?: string;
    gender: string;
    email: string;
    phonePrimary: string;
    imageUrl: string;
    department: string;
    designation: string;
    emailList?: string[];
    primaryPhoneList?: string[];
    employeeId?: string;
    caste: string;
    aadharNbr: string;
    bloodGroup: string;
    dateOfBirth: string;
    dateOfJoining: string;
    experienceSummary: string;
    qualification: string;
    techSupportInd: string;
    recordStatus: string;
    phoneSecondary: string;
    isActive: string;
    address?: Address;
    auditFields?: AuditFields;
}

export class EmployeeBasicInfo {
    employeeProfileId?: number;
    employeeId: string;
    namePrefix: string;
    firstName: string;
    userName: string;
    middleName: string;
    lastName: string;
    fullName?: string;
    gender: string;
    email: string;
    phonePrimary: string;
    imageUrl: string;
    department: string;
    designation: string;
    isActive: string;
    emailList?: string[];
    primaryPhoneList?: string[];
}

export class EmployeeLeaveRequest {
    leaveRequestId: number;
    employeeBasicInfoDTO: EmployeeBasicInfo;
    leaveType: string;
    description: string;
    startDate: string;
    endDate: string;
    requestStatus: string;
    comments: string;
    actionTakenBy: string;
    actionTakenTs: string;
    isActive: string;
    qualification: string;
}

export class Birthday {
    firstName: string;
    lastName: string;
    degreeName: string;
    deptName: string;
    courseName: string;
    classroomName: string;
    birthday: string;
    profileType: string;
    imageUrl: string;
}
export class EmployeeField {
    fieldName: string;
    jsonFieldName: string;
    displayValue: string;
    fieldType: string;
    lookupFieldName?: string;
}

export class EmployeeMapping {
    employeeProfileId: number;
    employeeId: number;
    fullName: string;
    department: string;
    designation: string;
    selected: boolean
}

export class MessageEmployee {
    department: string;
    designation: string;
    email: string;
    emailList: string[];
    employeeId: string;
    employeeProfileId: number
    firstName: string;
    fullName: string;
    gender: string;
    imageUrl: string;
    lastName: string;
    middleName: string;
    namePrefix: string;
    phonePrimary: string;
    primaryPhoneList: string[];
}

export class EmpBankAccount {
    employeeBankAccountId: number;
    accountNickName: string;
    bankName: string;
    accountNumber: string;
    ifscCode: string;
    employee: Employee;
}