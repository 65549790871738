import { AuditFields } from "./common";
import { EmployeeBasicInfo } from './employee';

export class School {
  schoolCd: string;
  branchName: string;
  cityNm: string;
  currentAcademicYear: string;
  district: string;
  educationBoardId: number;
  emailAddress1: string;
  emailAddress2: string;
  enrollmentDate: Date;
  examPattern: string;
  expiryDate: Date;
  groupCd: string;
  isActive: string;
  licenceType: string;
  logoLocation: string;
  logoUrl: string;
  nameImageLocation: string;
  phoneNumber1: string;
  phoneNumber2: string;
  phoneNumber3: string;
  phoneNumber4: string;
  postalCd: string;
  profileType: string;
  progressReportLine1: string;
  progressReportLine2: string;
  progressReportLine3: string;
  recordStatus: string;
  schoolName: string;
  state: string;
  streetAddress: string;
  subscriptionAmount: number;
  zoneCd: string;
  auditFields?: AuditFields;
}


export class SchoolSubject {
  isActive: string;
  isOptionalSubject: string;
  subjectName: string;
  displayOrder: number;
  subjectId: number;
  teacher: EmployeeBasicInfo;
  classroomId: number;
  classroomSubjectId: number;
  teacherName: string;
    defaultSchoolSubjectSetupId: any;
}


export class AcademicYear {
  academicYear: string;
  academicYearId: number;
  endDate: string;
  recordStatus: string;
  startDate: string;
}

export enum AcademicYearStatus {
  CURRENT = "CURRENT",
  PREVIOUS = "PREVIOUS",
  FUTURE = "FUTURE",
}
