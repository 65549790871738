import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from './validation-service';

@Component({
    selector: 'validation-message',
    template: `
        <div class="app-text-danger" [hidden]="!errorMessage">{{errorMessage}}</div>
    `,
    styles: [`
        .app-text-danger{
            width: 100%;
            margin-top: 0.25rem;
        }
    `],
})
export class ValidationMessage {


    @Input("control")
    control: FormControl;

    @Input("message")
    message: string;

    @Input("isFormSubmitted")
    formSubmitted: boolean;


    constructor() { }

    get errorMessage() {
        for (let propertyName in this.control?.errors) {
            if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.formSubmitted)) {
                return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName], this.message);
            }
        }
        return null;
    }
}

