export class Admission {
    enquiryId: number;
    studentProfileId: number;
    studentFirstName: string;
    studentLastName: string;
    admittedClass: AdmittedClass;
    academicYear: string;
    enquiryDate: Date;
    boardingType: string;
    religion: string;
    gender: string;
    dateOfBirth: Date;
    motherTongue: string;
    aadharNbr: string;
    previousSchool: string;
    parentAnualIncome: string;
    campaignArea: string;
    enquiryStatus: string;
    leadSource: string;
    channel: string;
    referredBy: string;
    nextFollowUpDate: Date;
    assignedDate: Date;
    admittedDate: Date;
    dropDate: Date;
    dropOutReason: string;
    assignedTo: AssignTo;
    classroomId: number;
}

export class AdmittedClass {
    courseId: number;
    courseName: string;
    displayOrder: number;
    deptId: number;
    isActive: string;
    classroomsList: ClassRooms
}

export class ClassRooms {
    classroomId: number;
    classroomName: string;
    degreeId: number;
    degreeName: string;
    deptId: number;
    deptName: string;
    courseId: number;
    courseName: string;
    displayOrder: number;
    isActive: string;
}

export class AssignTo {
    employeeProfileId: number;
    employeeId: string;
    fullName: string;
    department: string;
    designation: string;
    phonePrimary: string;
    email: string;
}

export class EnquiryNotes {
    noteId: number;
    enquiryId: number;
    description: string;
    auditFields: AuditField;
}

export class AuditField {
    createdBy: string;
    updatedBy: string;
    createdTs: string;
    updatedTs: string;
}

export class EnquiryDropInfo {
    enquiryId?: number;
    dropOutReason: string;
    dropOutDate: string;
    dropOutClass: string;
    droppedClassroomId: number;
  }