import { Injectable } from '@angular/core';
import {CRUDService} from "./crud.service";
import {DocumentUpload} from "models";
import {BaseService} from "./base.service";
import {APIConstant} from "../../../../public/src/app/core/constant";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UploadServiceSchoolChangeService extends CRUDService<DocumentUpload> {

    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.basePath);
    }

    getDocument(data: any): Observable<any> {
        if (data.contentType) {
            return this.baseService.get<any>(`${APIConstant.basePath}/documents/get?module=` + data.module + '&fileName=' + data.fileName + '&contentType=' + data.contentType);
        } else if (data.reGenerateFlag) {
            return this.baseService.get<any>(`${APIConstant.basePath}/documents/get?module=${data.module}&fileName=${data.fileName}&reGenerateFlag=${data.reGenerateFlag}`);
        } else {
            return this.baseService.get<any>(`${APIConstant.basePath}/documents/get?module=` + data.module + '&fileName=' + data.fileName);
        }
    }
}
