export class ScholasticMarks {
    categorySkillList: StudentMarksCategory[];
    grade: string;
    gradePoint: string | number;
    marksScored: string | number;
    maxMarks: string | number;
    subjectName: string;
    subjectId: string | number;
}

export class StudentMark {
    coScolasticMarksList: any[];
    examName: string;
    scholasticMarksList: ScholasticMarks[];
    testList: StudentMarksTest[];
    disciplineMarksList: any[];
}

export class StudentMarksSkill {
    grade: string;
    skillName: string;
    skillId: number | string;
}

export class StudentMarksSubCategory {
    skillList: StudentMarksSkill[];
    subCategoryName: string;
    subCategoryId: number | string;
}

export class StudentMarksCategory {
    categoryName: string;
    subCategorySkillList: StudentMarksSubCategory[];
    categoryId: number | string;
}

export class StudentMarksTest {
    subjectMarksList: ScholasticMarks[];
    testName: string;
    testId: number | string;
}