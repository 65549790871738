export class Apps {
    moduleName: string;
    route: string;
    image: string;
}

export class AppConfig {
    publicAPIs: string[];
    apiPath: string;
}

export class AppsList {
    id: number;
    username: string;
    appName: string;
    orgCodes: string;
    userUniqueId: number;
    isDefault: boolean;
}

export class AssignedSchool {
    id?: any;
    username: string;
    appName: string;
    orgCodes: string;
    schoolCode: string;
    orgLocation?: any;
    schoolType?: any;
    userUniqueId: number;
    isDefault?: any;
    url?: any;
    schoolName?: any;
    studentName?: any;
    employeeName?: any;
    employeeCount?: any;
    studentCount?: any;
    className?: any;
    classroomId?: any;
    imageSrc: any;
}
