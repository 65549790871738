import { Employee, EmployeeBasicInfo } from './employee';

export class SecurityUser {
    id: number;
    employeeBasicInfoDTO: EmployeeBasicInfo;
    appName: string;
    employee: Employee;
    isActive: string;
    mobile: number;
    securityRoleList: [];
    student: string;
    username: string;
}


