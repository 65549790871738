import { MemberBasicInfo } from './member';
import { BookBasicInfo } from './book';
import { AuditFields } from './common';

export class WaitingList {
    id: number;
    book: BookBasicInfo;
    member: MemberBasicInfo;
    entryDate: string;
    fulfilledDate?: string;
    recordStatus: string;
    auditFields: AuditFields;

    // UI
    index?: number;
}