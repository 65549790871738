import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

interface Message {
    type: string;
    payload: any;
}

type MessageCallback = (payload: any) => void;

@Injectable({
    providedIn: "root"
})
export class EventBusService {
    private handler = new Subject<Message>();

    broadcast(type: any, payload?: any) {
        this.handler.next({ type, payload });
    }

    on(type: string, callback: MessageCallback): Subscription {
        return this.handler
            .pipe(filter(message => message.type === type))
            .pipe(map(message => message.payload))
            .subscribe(callback);
    }
}