export class EnquiryContact {
    enquiryId: number;
    primaryName: string;
    primaryMobile: string;
    primaryEmail: string;
    photoPrimary: string;
    primaryOccupation: string;
    primaryRelation: string;
    secondaryName: string;
    secondaryMobile: string;
    secondaryEmail: string;
    photoSecondary: string;
    secondaryOccupation: string;
    secondaryRelation: string;
    streetAddress: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    district: string;
    state: string;
    postalCd: string;
    country: string;
    customizedField16: string;
    customizedField17: string;
    customizedField18: string;
    customizedField19: string;
    customizedField20: string;
    customizedField21: string;
    customizedField22: string;
    customizedField23: string;
    customizedField24: string;
    customizedField25: string;
}