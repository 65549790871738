import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;
const loginAPIPath = environment.loginAPIPath;

export const APIConstant = {
    basePath: apiPath,
    login: `${loginAPIPath}/auth/login`,
    userAccess: `${loginAPIPath}/auth/user-access`,
    employeeStrength: `${apiPath}/hr/employees/current-strength`,
    studentStrength: `${apiPath}/students/current-strength`,
    studentAttendance: `${apiPath}/attendance/students/percentage`,
    employeeAttendance: `${apiPath}/attendance/employees/percentage`,
    sendotp:`${loginAPIPath}/auth/otp/send`,
    validateotp:`${loginAPIPath}/auth/validateOtp`,
    schoolcreation: `${apiPath}/school/create-profile`,
    defaultSetup: `${apiPath}/school/setup/create-from-default-setup`,
    changePassword: `${loginAPIPath}/auth/login-account/changePassword`,
    admissionData: `${apiPath}/admissions/enquiries/enquiry-and-contact`
}

export const PublicAPI = [
    APIConstant.login
]
