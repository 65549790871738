import { Course } from "./course";

export class Department {
    deptId: number;
    departmentName: string;
    displayOrder: number;
    isActive: boolean;
    coursesList: Course[];
    degreeId: any;
    degree: any;
    constructor(obj: { departmentName: string; displayOrder: number; isActive: boolean; coursesList: Course[]; deptId?: number, degreeId?: any }) {
        this.deptId = obj.deptId || 0;
        this.departmentName = obj.departmentName || "";
        this.displayOrder = obj.displayOrder || 0;
        this.isActive = obj.isActive || false;
        this.coursesList = obj.coursesList || [];
        this.degreeId = obj.degreeId;
    }
}
