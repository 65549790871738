import { BookBasicInfo } from './book';
import { AuditFields } from './common';

export class Purchase {
    id: number;
    libraryCode: string;
    bookBasicInfo: BookBasicInfo;
    purchaseDate: string;
    supplier: string;
    quantity: number;
    price: number;
    remarks: string;
    recordStatus: string;
    auditFields?: AuditFields;

    // UI Fiels
    index?: number;
}
