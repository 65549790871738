<div class="card text-center justify-content-center shaodw-lg card-1 border-0 bg-white px-sm-2" *ngIf="templateData?.length">
    <div class="card-body show">
        <div class="radio-group row justify-content-between px-3 text-center a">
            <div class="col-auto mr-sm-2 mx-1 card-block py-0 text-center radio" [ngClass]="item.active ? 'selected' : ''" *ngFor="let item of templateData; let i = index"
                (click)="onCardClick(i)">
                <div class="flex-row">
                    <div class="col">
                        <div class="pic">
                            <img class="irc_mut img-fluid" *ngIf="!item.previewUrl" src="https://i.imgur.com/bGCqROr.png" width="100" height="200">
                            <embed [src]="item.previewUrl" *ngIf="item.previewUrl" style="width: 100%;height: 200px" class="irc_mut img-fluid" type="application/pdf">
                        </div>
                        <p>{{item.displayName}}</p>
                        <div class="download-btn">
                            <button *ngIf="item.previewUrl" class="app-btn outline" (click)="download.emit(item?.downloadUrl || item?.previewUrlForTs || item?.previewUrl)">
                                <i class="fa fa-download mr-1" aria-hidden="true"></i>
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>