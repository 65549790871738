import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfig } from 'models';

export const SPECTRUM_CONFIG = new InjectionToken('spectrumConfig');

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {

    private _configSubject: BehaviorSubject<AppConfig>;
    
    constructor(
        @Inject(SPECTRUM_CONFIG) public _config: AppConfig
    ) {
        this._init();
    }

    set config(value) {
        let config = this._configSubject.getValue();
        config = Object.assign({}, config, value);
        this._configSubject.next(config);
    }

    get config(): Observable<AppConfig> {
        return this._configSubject.asObservable();
    }

    private _init(): void {
        this._configSubject = new BehaviorSubject(null);
    }
}

