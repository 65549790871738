import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-spinner-box',
    templateUrl: './spinner-box.component.html',
    styleUrls: ['spinner-box.component.scss']
})

export class SpinnerBoxComponent {

    @Input()
    loadingText: string = 'Loading...';

    constructor() {
    }

}
