import { Component, OnInit } from "@angular/core";
import { AppService, RoleService, UserAuthService } from 'projects/common/src/public-api';
import { Router } from "@angular/router";

@Component({
    template: "",
})
export class ValidateComponent implements OnInit {

    constructor(private authService: UserAuthService,
        private router: Router,
        private appService: AppService,
        private roleService: RoleService,) { }

    ngOnInit() {
        this.getToken();
        this.getSecurityGroups();
    }

    private async getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        if (token) {
            window.sessionStorage.setItem('token', token);
            window.sessionStorage.setItem('orgCode', urlParams.get('code'));
            window.sessionStorage.setItem('employeeId', urlParams.get('employeeId'));
            window.sessionStorage.setItem('employeeName', urlParams.get('employeeName'));
            window.sessionStorage.setItem('school', urlParams.get('schoolName'));
            window.sessionStorage.setItem('userName', urlParams.get('userName'));
            setTimeout(() => {
                    this.router.navigateByUrl("/", {
                        preserveQueryParams: false,
                    });
            }, 900);

            // const code = urlParams.get("code");
            // await this.authService.saveToken(token);
            // this.authService.saveOrgCode(code);
            // this.schoolService.getSchoolProfileType().subscribe((response) => {
            //     this.router.navigateByUrl(response.type === Type.school ? "/class-section" : "/degree-department", {
            //         preserveQueryParams: false,
            //     });
            // });
            // this.getSecurityGroups();
            // this.getColumnConfig();
            // setTimeout(() => {
            //     this.router.navigateByUrl('/dashboard', { preserveQueryParams: false });
            // }, 0);
        }
    }

    private getSecurityGroups() {
        this.roleService.getSecurityGroups('OnlineStore');
      }
}
