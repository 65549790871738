import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { LookupComponent } from "../lookup/lookup.component";
import { LookupCategory } from 'models';
import { LookupService } from '../../service/lookup.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: "app-select-box",
    templateUrl: "./select-box.component.html",
    styleUrls: ["select-box.component.scss"],
})
export class SelectBoxComponent implements OnInit {

    @Input() group: FormGroup;
    @Input() controlName: string;
    @Input() module: string;
    @Input() isFormSubmitted: boolean;
    @Input() label: string;
    @Input() validationMessage: string;
    @Input() hasReadAccess: boolean;
    @Input() allowAdd: boolean;
    @Input() categoryName: LookupCategory;
    @Input() displayName: LookupCategory;
    @Input() addAsterisk: boolean;
    @Input() customFields: any;

    @Output() change: EventEmitter<any> = new EventEmitter();

    items: any[] = [];
    loading = false;
    lookupModalRef: BsModalRef;
    isOpen = false;

    constructor(
        private modalService: BsModalService,
        private lookupService: LookupService,
        private spinner: NgxSpinnerService
    ) {
        this.onAdd = this.onAdd.bind(this);
    }

    ngOnInit() {
        if (this.categoryName) {
            this.getList();
        }
    }

    private getList() {
        this.spinner.show();
        this.lookupService.lookup(this.categoryName).subscribe((x) => {
            this.spinner.hide();
            if (x && x.length > 0) {
                this.items = x;
            } else {
                this.items = [];
            }
        }, (err) => {
            this.spinner.hide();
            this.items = [];
        });
    }

    onAdd(event: any) {
        return new Promise((resolve) => {
            this.loading = true;
            return this.lookupService
                .add({ fieldName: this.categoryName, value: event } as any)
                .toPromise()
                .then(() => {
                    resolve(event);
                })
                .finally(() => {
                    this.loading = false;
                });
        });
    }

    onChange(event: any) {
        if (this.change) {
            this.change.emit(event);
        }
    }

    manage() {
        const initialState = {
            categoryName: this.categoryName,
            displayName: this.displayName,
            module: this.module
        };
        this.lookupModalRef = this.modalService.show(LookupComponent, {
            initialState,
        });
        this.lookupModalRef.content.onClose.subscribe((result) => {
            this.items = [...result];
        });
    }
}
