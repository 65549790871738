import { Injectable } from "@angular/core";
import { BaseService } from './base.service';
import { AppConfigService } from './config.service';
import { CommonAPIConstant } from '../constants';
import { CommonUtility } from "../utilities";
import { Router } from "@angular/router";
import { environment } from "projects/public/src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class RoleService {

    private securityGroups: string[] = [];

    constructor(protected baseService: BaseService, private configService: AppConfigService, private router: Router) {
    }

    getSecurityGroups(module: string) {
        let windowLocation = window.location;
        let href = window.location.href;
        const reqParams = CommonUtility.convertObjectToParams({ module });
        this.baseService.get(`${this.configService._config.apiPath}${CommonAPIConstant.securityGroups}?${reqParams}`).subscribe((response: string[]) => {
            this.securityGroups = response;
            sessionStorage.setItem('security', JSON.stringify(this.securityGroups));
        }, error => {
            this.securityGroups = [];
            console.log(window.location.origin);
            if ((error.status === 400 || error.status === 408) && 
                (
                window.location.href.includes('http://localhost:5021') || 
                window.location.href.includes('https://pph.schooladminportal.com') || 
                window.location.href.includes('https://pph.spectrum.myakshar.com'))) {
                sessionStorage.clear();
                // if(href.includes('/task/')){
                //     let origin = windowLocation.origin;
                //     href = origin+"/validate"+(new URL(href).pathname);
                // }
                window.location.href = environment.loginAPIPath + '/login?redirectURL=' + encodeURIComponent(href);
            }
        });
    }

    securityRights(securityGroup: string): boolean {
        if (Array.isArray(JSON.parse(sessionStorage.getItem('security')))) {
            return this.securityGroups.includes(securityGroup);
        }
        return false;
    }

}
