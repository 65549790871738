import { AssessmentSkill } from "./assessment-skill";

export class AssessmentSubCategory {
    categoryId: number;
    subCategoryId: number;
    subCategoryName: string;
    displayOrder: number;
    isActive: boolean;
    skillList: AssessmentSkill[];
}
