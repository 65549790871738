<div class="modal-header">
    <h4 class="modal-title pull-left"></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row justify-content-center" *ngIf="(!studentDataList  && (schoolList && schoolList.length > 0))">
        <div class="mb-4 mt-2" style="margin-left: 0.25rem !important;">
            <h5>Select Your Institute</h5>
        </div>
        <div class="col-md-12">
            <div class="card card-margin" *ngFor="let item of schoolList">
                <div class="card-body pt-0" style="cursor: pointer;" (click)="selectSchool(item)"
                    [ngClass]="{'active' : item.orgCode === selectedSchool}">
                    <div class="widget-49">
                        <div class="widget-49-title-wrapper">
                            <div class="widget-49-date-primary">
                                <img [src]="item.url" class="img-app-list" />
                            </div>
                            <div class="widget-49-meeting-info">
                                <span class="widget-49-pro-title">{{item?.schoolName}}</span>
                                <span class="widget-49-meeting-time" *ngIf="item.orgCodes === selectedSchool"><i
                                        class="fa fa-map-marker mr-1"></i>Selected</span>
                                <span class="widget-49-meeting-time" *ngIf="item?.orgLocation"><i
                                        class="fa fa-map-marker mr-1"></i>{{item.orgLocation}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <ul class="list-group">
                <li class="list-group-item align-items-center d-flex my-1 border rounded-lg card-body-institute pt-0"
                    *ngFor="let item of schoolList" (click)="selectSchool(item)"
                    [ngClass]="{'active' : item.orgCode === selectedSchool}">
                    <img [src]="item.url" class="img app-image rounded" />
                    <div class="col">
                        <div class="d-flex w-100 align-items-center justify-content-between">
                            <div>{{item.schoolName}}</div>
                            <small *ngIf="item.orgCodes === selectedSchool">Selected</small>
                        </div>
                        <small>{{item.orgLocation}}</small>
                    </div>
                </li>
            </ul> -->
        </div>
    </div>

    <div class="row" *ngIf="(!schoolList  && (studentDataList && studentDataList.length > 0))">
        <div class="mb-4 mt-2" style="margin-left: 0.25rem !important;">
            <h5>Select Your Child</h5>
        </div>
        <div class="col-md-12">
            <div class="card card-margin" *ngFor="let item of studentDataList">
                <div class="card-body pt-0" style="cursor: pointer;" (click)="selectStudent(item)"
                    [ngClass]="{'active' : item.studentName === selectedStudent}">
                    <div class="widget-49">
                        <div class="widget-49-title-wrapper">
                            <div class="widget-49-date-primary">
                                <img [src]="item.url" class="img-app-list" />
                            </div>
                            <div class="widget-49-meeting-info">
                                <span class="widget-49-pro-title">{{item?.studentName}}</span>
                                <span class="widget-49-meeting-time" *ngIf="item?.className"><i
                                        class="fa fa-map-marker mr-1"></i>{{item?.className}}</span>
                                <span class="widget-49-meeting-time" *ngIf="item?.orgName"><i
                                        class="fa fa-map-marker mr-1"></i>{{item?.orgName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <ul class="list-group">
                <li class="list-group-item align-items-center d-flex my-1 border rounded-lg card-body-institute pt-0"
                    *ngFor="let item of studentDataList" (click)="selectStudent(item)"
                    [ngClass]="{'active' : item.studentName === selectedStudent}">
                    <img [src]="item.url" class="img app-image rounded" />
                    <div class="col">
                        <div class="d-flex w-100 align-items-center justify-content-between">
                            <div>{{item?.studentName}}</div>
                        </div>
                        <small >{{item?.className}}</small>
                        <small>{{item?.orgName}}</small>
                    </div>
                </li>
            </ul> -->
        </div>
    </div>










    <div class="row justify-content-center "
        *ngIf="((studentDataList && studentDataList.length  > 0) && (schoolList && schoolList.length > 0))">

        <div class="col-6">

            <div class="mb-4 mt-2" style="margin-left: 0.25rem !important;">
                <h5>Select Your Institute</h5>
            </div>
            <div class="app-list">
                <div class="row">
                    <div class="col-lg-12" *ngIf="schoolList && schoolList.length > 0">
                        <div class="card card-margin" *ngFor="let app of schoolList">
                            <div class="card-body pt-0" style="cursor: pointer;" (click)="selectSchool(app)"
                                [ngClass]="{'active' : app.orgCode === selectedSchool}">
                                <div class="widget-49">
                                    <div class="widget-49-title-wrapper">
                                        <div class="widget-49-date-primary">
                                            <img [src]="app.imageSrc" class="img-app-list" />
                                        </div>
                                        <div class="widget-49-meeting-info">
                                            <span class="widget-49-pro-title">{{app.schoolName}}</span>
                                            <span class="widget-49-meeting-time" *ngIf="app.orgLocation"><i
                                                    class="fa fa-map-marker mr-1"></i>{{app.orgLocation}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-6">

            <div class="mb-4 mt-2" style="margin-left: 0.25rem !important;">
                <h5>Select Your Child</h5>
            </div>
            <div class="app-list">
                <div class="row">
                    <div class="col-lg-12" *ngIf="studentDataList && studentDataList.length > 0">
                        <div class="card card-margin" *ngFor="let student of studentDataList">
                            <div class="card-body pt-0" style="cursor: pointer;" (click)="selectStudent(student)"
                                [ngClass]="{'active' : student.studentName === selectedStudent}">
                                <div class="widget-49">
                                    <div class="widget-49-title-wrapper">
                                        <div class="widget-49-date-primary">
                                            <img [src]="student.imageSrc" class="img-app-list" />
                                        </div>
                                        <div class="widget-49-meeting-info">
                                            <span class="widget-49-pro-title">{{student?.studentName}}</span>
                                            <span class="widget-49-meeting-time" *ngIf="student.className"><i
                                                    class="mr-1"></i>{{student.className}}</span>
                                            <span class="widget-49-meeting-time" *ngIf="student?.orgName"><i
                                                    class="mr-1"></i>{{student?.orgName}}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!--    </div>-->
</div>
<div class="modal-footer">
    <button type="button" class="app-btn" (click)="change()" [disabled]="enableSubmit()"><i class="fa fa-window-restore"
            aria-hidden="true"></i>&nbsp; Change</button>
</div>