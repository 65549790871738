
export const CommonConstant = {
    token: "token",
    orgCode: "orgCode",
    user: "user",
    studentId: "studentId",
    defaultPageSize: 10,
    reportDownloadPageSize: 1000,
};


export const Images = {
    user: "assets/images/user.png",
    school: "assets/images/default_group.jpeg",
    book: "assets/images/add-book.png",
};

export const Catergory = {
    TextBook: "TEXTBOOK",
    NoteBooK: "NOTEBOOK",
    Stationery_Item: "StationeryItem",
    NOTEBOOK_RULING: "NOTEBOOK_RULING",
    Notebook_Brand: "NotebookBrand",
    PAGES_COUNT: "PA;GES_COUNT",
    PAGE_SIZES: "PAGE_SIZES",
    Publisher: "Publisher",
    NotebookType: "NotebookType",
    Stationery_Brand: "StationeryBrand",
    Subject: "Subject",

};
