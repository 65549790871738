import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ToastrService } from 'ngx-toastr';
import { TreeviewConfig, TreeviewItem, TreeviewHelper, DropdownTreeviewComponent, TreeviewI18n } from 'ngx-treeview';
import { LookupService } from '../../service';
import { CommonUtility } from '../../utilities';
import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';

@Component({
    selector: 'app-single-year-head',
    templateUrl: './single-year-head.component.html',
    styleUrls: ['single-year-head.component.scss'],
    providers: [
        { provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n }
    ]
})

export class SingleYearHeadComponent implements OnInit, OnChanges {

    @Input()
    studentProfileId: number;

    @Input()
    classroomId: any;

    @Input()
    academicYear: any;

    @Input()
    value: number;

    @Input()
    data: any[] = null;

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter();

    @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;

    @ViewChild('dropdown', { static: true }) dropdown: BsDropdownDirective;

    buttonLabel: string = 'Please Select';
    // selectedItem : 

    config = TreeviewConfig.create({
        hasAllCheckBox: false,
        hasCollapseExpand: false,
        hasFilter: true,
        maxHeight: 350,

    });

    filterText: string;
    items: TreeviewItem[];
    private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;


    constructor(public i18n: TreeviewI18n,
        private lookupService: LookupService,
        private toastr: ToastrService) {
        this.dropdownTreeviewSelectI18n = i18n as DropdownTreeviewSelectI18n;
    }

    ngOnInit() {
        this.getData();
    }

    ngOnChanges(): void {
        this.updateSelectedItem();
    }

    private getData() {
        if (!this.data) {
            const params = {
                studentProfileId: this.studentProfileId,
                academicYear: this.academicYear,
                // classroomId: this.classroomId
            }
            this.lookupService.feeHeadsForPayment(params).subscribe(result => {
                this.items = this.structureTree(result);

            }, err=> {
                if(err.status == 406) {
                    this.toastr.error(err['error'].message, 'Error')
                }
            })
        } else {
            this.items = this.structureTree(this.data);
        }
    }

    private structureTree(result: any[], textBy: string = 'feeHead', valueBy: string = 'academicYear'): TreeviewItem[] {
        return result.map(item => {
            const parent = new TreeviewItem({
                text: '',
                value: item,
                children: []
            });
            if (textBy === 'termName') {
                parent.text = item.feeTerm.termName
            } else {
                parent.text = item[textBy]
            }
            if (item && item.feeHeadList && item.feeHeadList.length > 0) {
                parent.children = this.structureTree(item.feeHeadList, 'feeHead', 'feeHead');
            } else if (item && item.feeItemList && item.feeItemList.length > 0) {
                parent.children = this.structureTree(item.feeItemList, 'termName', 'feeTermSetupId');
            }

            return parent;
        })
    }

    select(item: TreeviewItem): void {
        if (!item.children) {
            this.selectItem(item);
        }
    }

    private updateSelectedItem(): void {
        if (CommonUtility.isNotNull(this.items)) {
            const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
            this.selectItem(selectedItem);
        }
    }

    selectItem(item: TreeviewItem): void {
        if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
            this.dropdownTreeviewSelectI18n.selectedItem = item;
            if (this.dropdownTreeviewComponent) {
                this.dropdownTreeviewComponent.onSelectedChange([item]);
            }
            if (item) {
                if (this.value !== item.value) {
                    this.value = item.value;
                    this.valueChange.emit(item.value);
                }
            }
        }
        if (this.dropdown) {
            this.dropdown.hide();
        }
        this.buttonLabel = this.dropdownTreeviewSelectI18n.getText();
    }

    cacheParent() {
        
    }
}
