export class AssessmentProperty {
    href: string;
    hreflang: string;
    title: string;
    type: string;
    deprecation: string;
    profile: string;
    name: string;
    templated: boolean;
}
