export enum ReportCategory {
    WELCOME_NOTIFICATION = "WELCOME_NOTIFICATION",
    STUDENT_ABSENT_REPORT = "STUDENT_ABSENT_REPORT",
    EMPLOYEE_ABSENT_REPORT = "EMPLOYEE_ABSENT_REPORT",
    STUDENT_NOTIFICATION = "STUDENT_NOTIFICATION",
    MARKS_REPORT = "MARKS_REPORT",
    FEE_RECEIPT = "FEE_RECEIPT",
    OTHER = "OTHER",
    PASSWORD_RESET_NOTIFICATION = "PASSWORD_RESET_NOTIFICATION",
    HOMEWORK = "HOMEWORK",
    FEEDBACK_REPORT = "FEEDBACK_REPORT",
    ONE_TIME_PASSWORD = "ONE_TIME_PASSWORD",
    FEEREMINDER = "FEE_REMINDER",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",
    STUDENT_LATE_ENTRY = "STUDENT_LATE_ENTRY",
    EMPLOYEE_LATE_ENTRY = "EMPLOYEE_LATE_ENTRY",
    EMPLOYEE_NOTIFICATION = "EMPLOYEE_NOTIFICATION",
    GENERAL_NOTIFICATION = "GENERAL_NOTIFICATION",
    DUE_REPORT = "DUE_REPORT",
    OVER_DUE_REPORT = "OVER_DUE_REPORT",
    CATEGORY_WISE_REPORT = "CATEGORY_WISE_REPORT",
    FEE_HEAD_WISE_REPORT = "FEE_HEAD_WISE_REPORT",
    FEE_DUE_NOTICE = "FEE_DUE_NOTICE",
    FEE_OVER_DUE_NOTICE = "FEE_OVER_DUE_NOTICE",
    STUDENT_HOLIDAY_NOTIFICATION_SINGLE_DAY = "STUDENT_HOLIDAY_NOTIFICATION_SINGLE_DAY",
    STUDENT_HOLIDAY_NOTIFICATION_MULTI_DAY = "STUDENT_HOLIDAY_NOTIFICATION_MULTI_DAY",
    EMPLOYEE_HOLIDAY_NOTIFICATION_SINGLE_DAY = "EMPLOYEE_HOLIDAY_NOTIFICATION_SINGLE_DAY",
    EMPLOYEE_HOLIDAY_NOTIFICATION_MULTI_DAY = "EMPLOYEE_HOLIDAY_NOTIFICATION_MULTI_DAY",
    GENERAL_ENQUIRY_NOTIFICATION = "GENERAL_ENQUIRY_NOTIFICATION",
    FOLLOWUP_ENQUIRY_NOTIFICATION = "FOLLOWUP_ENQUIRY_NOTIFICATION",
    Other = "Other"
}
