import { AuditFields } from './common';
import { AssignedSchool } from './app';

export class User {
    memberId: number;
    username: string;
    libraryCode: string;
    recordStatus: string;
    auditFields: AuditFields;
    securityRolesList: SecurityRolesList[];
}

export class SecurityRolesList {
    rolename: string;
    librarycode: string;
    recordStatus: string;
    auditFields: AuditFields;
    securityGroupsList: SecurityGroupsList[];
}

export class SecurityGroupsList {
    groupname: string;
    module: string;
    screen: string;
    accessType: string;
    remarks?: any;
    displayOrder: number;
    recordStatus: string;
}

export class LoginRequest {
    username: string;
    password: string;
}

export class LoginResponse {
    token: string;
    appsList: AssignedSchool[];
    route: string;
}