import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserAuthService } from '../service';

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private auth: UserAuthService) { }

    canActivate(): boolean {
        if (!this.auth.isLoggedIn()) {
            this.router.navigateByUrl('/login');
            return;
        }
        return true;
    }

}
