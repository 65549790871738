<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange">
    <div class="form-inline row-item">
        <i class="fa" [ngClass]="item.collapsed ? 'fa-angle-right' : 'fa-angle-down'" *ngIf="item.children"
            (click)="onCollapseExpand()" aria-hidden="true">
        </i>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked"
                (ngModelChange)="onCheckedChange()" [disabled]="item.disabled" [indeterminate]="item.indeterminate" />
            <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
                {{item.text}}
            </label>
        </div>
    </div>
</ng-template>

<span class="has-float-label">
    <ngx-dropdown-treeview id="tree-dropdown" [config]="config" [items]="items" [itemTemplate]="itemTemplate"
        (selectedChange)="onSelectedChange($event)">
    </ngx-dropdown-treeview>
    <label class="label" for="tree-dropdown">Classroom 
        <!-- <span class="required label">*</span> -->
    </label>
    <!--
    <div class="right">
       <button *ngIf="valuePresent && !treeClosed" [ngClass]="{ 'app-btn' : true, outline: true, flicker: valueChanged }" (click)="closeTreeView()">Done</button>
    </div>
-->
</span>
