export class Access {
    accessType: string;
    displayOrder: any;
    groupName: string;
    module: string;
    remarks: string;
    screen: string;
    screenId: any;
    isSeleted: boolean;
}

export class Screen {
    accessList: Access[]
    screenName: string;
    view: { value: boolean, isVisible: boolean, label: string };
    edit: { value: boolean, isVisible: boolean, label: string };
    add: { value: boolean, isVisible: boolean, label: string };
    delete: { value: boolean, isVisible: boolean, label: string };
}

export class Module {
    moduleName: string;
    screensList: Screen[];
}

export class SecurityRole {
    isDefault: boolean
    modulesList: Module[]
    roleName: string;
}


