import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { LookupComponent } from "../lookup/lookup.component";
import { LookupCategory } from 'models';
import { LookupService } from '../../service/lookup.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: "app-select-add-box",
    templateUrl: "./select-add-box.component.html",
    styleUrls: ["select-add-box.component.scss"],
})
export class SelectAddBoxComponent implements OnInit {

    @Input() group: FormGroup;
    @Input() controlName: string;
    @Input() module: string;
    @Input() isFormSubmitted: boolean;
    @Input() label: string;
    @Input() validationMessage: string;
    @Input() hasReadAccess: boolean;
    @Input() allowAdd: boolean;
    //@Input() categoryName: LookupCategory;
    @Input() displayName: LookupCategory;
    @Input() addAsterisk: boolean;
    @Input() customFields: any;
    @Input() items: any;
    @Input() modalOpen: any;
    @Output("fireFunc") fireFunc: EventEmitter<any> = new EventEmitter();
    @Output("parentModal") parentModal: EventEmitter<any> = new EventEmitter();
    //@Output("getTextBooksDetails") getTextBooksDetails: EventEmitter<any> = new EventEmitter();

    @Output() change: EventEmitter<any> = new EventEmitter();

    //items: any[] = [];
    loading = false;
    lookupModalRef: BsModalRef;
    isOpen = false;

    constructor(
        private modalService: BsModalService,
        private lookupService: LookupService,
        private spinner: NgxSpinnerService
    ) {
        this.onAdd = this.onAdd.bind(this);
    }

    ngOnInit() {
        // if (this.categoryName) {
        //     this.getList();
        // }
    }

    // private getList() {
    //     this.spinner.show();
    //     this.lookupService.lookup(this.categoryName).subscribe((x) => {
    //         this.spinner.hide();
    //         if (x && x.length > 0) {
    //             this.items = x;
    //         } else {
    //             this.items = [];
    //         }
    //     }, (err) => {
    //         this.spinner.hide();
    //         this.items = [];
    //     });
    // }

    onAdd(event: any) {
        // return new Promise((resolve) => {
        //     this.loading = true;
        //     return this.lookupService
        //         .add({ fieldName: this.categoryName, value: event } as any)
        //         .toPromise()
        //         .then(() => {
        //             resolve(event);
        //         })
        //         .finally(() => {
        //             this.loading = false;
        //         });
        // });
    }

    onChange(event: any) {
        if (this.change) {
            this.change.emit(event);
        }
    }

    manage() {
        
        this.parentModal.emit();
        const initialState = {
            textbookData:{
                courseName : this.customFields
            }
        };
        console.log(this.customFields);
        this.lookupModalRef = this.modalService.show(this.modalOpen, {
            initialState,
            class : "add-items modal-sm"
        });
        this.lookupModalRef.content.onClose.subscribe((result) => {
            this.fireFunc.emit();
            this.parentModal.emit();
        });
    }
}
