import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['accordion.component.scss']
})

export class AccordionComponent {

    @Input() idBodyVisible: boolean = false;
    @Output() otherAccordionVisible: EventEmitter<boolean> = new EventEmitter();

    showHideBody() {
        this.idBodyVisible = !this.idBodyVisible;
        this.otherAccordionVisible.emit();
    }

}
