import { AuditFields, APIRequest } from './common';

export class MemberSearchResult {
    memberId: number;
    firstName: string;
    lastName: string;
    membershipId: string;
    memberSince: string;
    degree?: string;
    department?: string;
    classname: string;
    section: string;
    mobileNumber: string;
    email: string;
    membershipStatus: string;
    expiryDate?: any;
    type: string;
    designation: string;
}

export class MemberRequest extends APIRequest {
    member: Member;
}

export class Member {
    memberId: number;
    memberBasicInfo: MemberBasicInfo;
    dateOfBirth: string;
    type: string;
    memberSince: string;
    subscriptionStatus: string;
    expiryDate: string;
    gender: string;
    biometricId: number;
    recordStatus: string;
    auditFields: AuditFields;
    contactDetail: ContactDetail;
}

export class ContactDetail {
    contactId: number;
    addrLine1: string;
    addrLine2: string;
    city: string;
    district: string;
    state: string;
    pinCode: string;
    mobile: string;
    email: string;
}

export class MemberBasicInfo {
    memberId: number;
    membershipId: string;
    libraryCode: string;
    firstName: string;
    lastName: string;
    degree: string;
    department: string;
    className: string;
    section: string;
    designation: string;
    recordStatus: string;
}

export class MemberInfo {
    memberId: number;
    firstName: string;
    lastName: string;
    membershipId: string;
    memberSince: string;
    degree: string;
    department: string;
    classname: string;
    section: string;
    designation?: any;
    mobileNumber?: any;
    email?: any;
    subscriptionStatus: string;
    expiryDate?: any;
    type?: any;
    imageUrl: string;
}

export class MemberSummary {
    totalBooksIssued: number;
    pendingReturns: number;
    overdueBooks: number;
    totalDelayedReturns: number;
    totalLibraryVisits: number;
}

export class MemberPunch {
    id: number;
    member: MemberBasicInfo;
    date: string;
    checkInTime: string;
    checkOutTime: string;
    libraryCode: string;
    recordStatus: string;
    auditFields?: AuditFields;

    //UI  Field
    day?: string;
}
