import { Department } from "./department";

export class Degree {
    degreeId: number;
    degreeName: string;
    displayOrder: number;
    isActive: boolean;
    deptList: Department[];
    constructor(obj: { degreeName: string; displayOrder: number; isActive: boolean; deptList: Department[]; degreeId?: number }) {
        this.degreeId = obj.degreeId || 0;
        this.degreeName = obj.degreeName || "";
        this.displayOrder = obj.displayOrder || 0;
        this.isActive = obj.isActive || false;
        this.deptList = obj.deptList || [];
    }
}
