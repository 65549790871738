export class Skill {
    area: string;
    categoryId: number;
    categoryName: string;
    classroomId: number;
    displayOrder: number;
    skillId: number;
    skillName: string;
    subCategoryId: number;
    subCategoryName: string;
    subjectId: number;
}

export class SubCategory {
    categoryId: number;
    categoryName: string;
    displayOrder: number;
    skillList: Skill[];
    subCategoryId: number;
    subCategoryName: string;
}

export class Category {
    area: string;
    categoryId: number;
    categoryName: string;
    displayOrder: number;
    subCategoriesList: SubCategory[]
}