import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from './base.service';
import { CRUDService } from './crud.service';
import { DocumentUpload } from 'models';
import { CommonUtility } from '../utilities';
import { catchError, mergeMap } from 'rxjs/operators';
import { APIConstant } from 'projects/public/src/app/core';

@Injectable({
    providedIn: "root",
})
export class UploadService extends CRUDService<DocumentUpload> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.basePath);
    }

    getAllDocumentsbyFolder(data: any): Observable<any> {

        return this.baseService.get<any>(`${APIConstant.basePath}/documents/allFilesInFolder?module=` + data.module + '&folderName=' + data.folderName);

    }

    getDocument(data: any): Observable<any> {
        if (data.contentType) {
            return this.baseService.get<any>(`${APIConstant.basePath}/documents/get?module=` + data.module + '&fileName=' + data.fileName + '&contentType=' + data.contentType);
        } else if (data.reGenerateFlag) {
            return this.baseService.get<any>(`${APIConstant.basePath}/documents/get?module=${data.module}&fileName=${data.fileName}&reGenerateFlag=${data.reGenerateFlag}`);
        } else {
            return this.baseService.get<any>(`${APIConstant.basePath}/documents/get?module=` + data.module + '&fileName=' + data.fileName);
        }
    }

    uploadDocument(module: string, documentId: any, imageType: string, file: File): Observable<any> {
        let fileName = documentId;
        if (imageType && imageType != '' && documentId) {
            fileName = `${documentId}_${imageType}`;
        } else {
            fileName = documentId;
        }
        // const fileName = imageType && imageType != '' && documentId ? `${documentId}_${imageType}` : documentId;
        const queryParams = CommonUtility.convertObjectToParams({
            module: module,
            fileName: fileName,
            contentType: file.type
        });
        return this.baseService.put(`${APIConstant.basePath}/documents/upload?${queryParams}`, null).pipe(
            mergeMap((response: any) => {
                return this.baseService.put(response.URL, file);
            })
        );
    }
}
