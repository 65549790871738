import { NgModule } from '@angular/core';
import {
	ValidationMessage,
	ValidationLinkMessage,
	ImageUploadComponent,
	SpinnerBoxComponent,
	TopHeaderBarComponent,
	ProgressComponent,
	LookupComponent,
	SelectBoxComponent,
	SelectAddBoxComponent,
	SingleClassroomComponent,
	MultipleClassroomComponent,
	MultipleTestComponent,
	MultipleSkillComponent,
	SingleSkillComponent,
	AccordionComponent,
	SingleHeadComponent,
	MultipleHeadComponent,
	SingleTestComponent,
	SchoolChangePopupComponent,
	SingleYearHeadComponent,
	ColumnConfigComponent,
	DateTimePickerComponent
} from './components';
import {
	HrefPreventDefaultDirective,
	ImagePreviewDirective,
	FocusFirstInvalidFieldDirective,
	OutsideClickDirective,
	ImageFallbackDirective,
} from './directives';
import {
	ObjectToArrayPipe,
	DisplayDatePipe,
	DisplayTimePipe,
	ServerDisplayDatePipe
} from './pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TreeviewModule } from 'ngx-treeview';
import { PreviewComponent } from './components/preview/preview.component';
import { ChangeTemplateComponent } from './components/change-template/change-template.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NguCarouselModule } from "@ngu/carousel";
import { SinglePreviewComponent } from './components/single-preview/single-preview.component';
import { NgbDatepickerModule, NgbPopoverModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { VideoUploadComponent } from './components/video-upload/video-upload.component';

const components = [
	ValidationMessage,
	ValidationLinkMessage,
	ImageUploadComponent,
	VideoUploadComponent,
	SpinnerBoxComponent,
	TopHeaderBarComponent,
	ProgressComponent,
	SelectBoxComponent,
	SelectAddBoxComponent,
	LookupComponent,
	SingleClassroomComponent,
	MultipleClassroomComponent,
	MultipleTestComponent,
	MultipleSkillComponent,
	SingleSkillComponent,
	AccordionComponent,
	SingleHeadComponent,
	MultipleHeadComponent,
	SingleTestComponent,
	SchoolChangePopupComponent,
	SingleYearHeadComponent,
	ColumnConfigComponent,
	PreviewComponent,
	ChangeTemplateComponent,
	SinglePreviewComponent,
	DateTimePickerComponent
];

const directives = [
	HrefPreventDefaultDirective,
	ImagePreviewDirective,
	FocusFirstInvalidFieldDirective,
	OutsideClickDirective,
	ImageFallbackDirective
];

const pipes = [
	ObjectToArrayPipe,
	DisplayDatePipe,
	DisplayTimePipe,
	ServerDisplayDatePipe
];


const modules = [
	HttpClientModule,
	NgSelectModule,
	NgxSpinnerModule,
	CommonModule,
	ReactiveFormsModule,
	FormsModule,
	SweetAlert2Module
]

const entryComponents = [
	LookupComponent,
	SchoolChangePopupComponent
]

@NgModule({
	declarations: [
		...components,
		...directives,
		...pipes
	],
	imports: [
		...modules,
		NgbDatepickerModule,
		NgbTimepickerModule,
		NgbPopoverModule,
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		ToastrModule.forRoot({
			preventDuplicates: true,
		}),
		TreeviewModule.forRoot(),
		NguCarouselModule
	],
	entryComponents: [
		...entryComponents
	],
	exports: [
		...components,
		...directives,
		...pipes,
		...entryComponents,
		...modules
	],
})
export class AppCommonModule { }
