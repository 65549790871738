import { ProfileType } from './enum/ProfileType';

export class Attendance {
    rollNbr: string;
    employeeId: string;
    fullName: string;
    date: string;
    shiftId: number;
    shiftName: string;
    attendanceInd: string;
    lateEntryFlag: string;
    profileId: number;
    inTime: string;
    outTime: string;
    studentContact: string
    isLateEntry?: boolean
}

export class AttendanceReportRequest {
    profileType: ProfileType;
    fromDate: string;
    toDate: string;
    department?: string;
    classroomId?: string;
}

export class ShiftWiseAttendance {
    shiftName: string;
    attendanceInd: string;
    lateEntryFlag: string;
}

export class DayAttendanceList {
    date: string;
    shiftwiseAttendanceList: ShiftWiseAttendance[];
    inTime: string;
    outTime: string;
}

export class AttendanceReport {
    profileId: number;
    rollNbr: string;
    employeeId: string;
    fullName: string;
    dayAttendanceList: DayAttendanceList[]
}

export class MessageAttendance {
    attendanceInd: string;
    date: string;
    emailList: string[];
    employeeId: any;
    fullName: string;
    inTime: any;
    lateEntryFlag: string;
    mobileList: string[];
    outTime: any;
    profileId: number;
    rollNbr: any;
    shiftId: number;
    shiftName: string;
    studentContact: any;
}

