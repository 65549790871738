import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from './base.service';
import { Apps, AssignedSchool } from 'models';
import { AppConfigService } from './config.service';
import { CommonAPIConstant } from '../constants';
import { CommonUtility } from "../utilities";
import { APIConstant } from "../../../../public/src/app/core/constant";
import { ThemeHelpersService } from "./theme-helpers.service";

@Injectable({
    providedIn: "root",
})
export class AppService {

    constructor(protected baseService: BaseService, private configService: AppConfigService, private themeHelper: ThemeHelpersService) {
    }

    get(): Observable<Apps[]> {
        return this.baseService.get(`${this.configService._config.apiPath}${CommonAPIConstant.app}`);
    }

    assignedSchoolList(): Observable<AssignedSchool[]> {
        return this.baseService.get(`${this.configService._config.apiPath}${CommonAPIConstant.assignedSchools}`);

    }

    datatableSetup(module: string): Promise<boolean> {
        // sessionStorage.removeItem('datatable');
        return new Promise<boolean>((resolve) => {
            const reqParams = CommonUtility.convertObjectToParams({ module });
            const url = `${this.configService._config.apiPath}${CommonAPIConstant.datatableSetup}?${reqParams}`
            console.log('url', url);
            this.baseService.get(url).subscribe((result) => {
                if (result) {
                    CommonUtility.datatable = result;
                    sessionStorage.setItem('datatable', JSON.stringify(result));
                    setTimeout(() => {
                        this.themeHelper.updateListener('datatable:update', result);
                    }, 500);
                }
                resolve(true)
            });

        })
    }

    getDocumentTemplates(data: any, url: string) {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get(`${url}?${params}`);
    }
    updateDocumentTemplate(params: any, url: string) {
        return this.baseService.put(`${url}`, params);
    }


    assignedStudentList(): Observable<any[]> {
        return this.baseService.get(`${this.configService._config.apiPath}${CommonAPIConstant.assignedSchools}`);

    }

    getUserAccessForParent(user: any): Observable<any> {
        return this.baseService.get(`${CommonAPIConstant.userAccess}?username=${user}&appName=SmartParent`);
    }

    getUserAccess(user: any): Observable<any> {
        return this.baseService.get(`${APIConstant.userAccess}?username=${user}&appName=Spectrum`);
    }
}
