import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'app-video-upload',
    templateUrl: './video-upload.component.html',
    styleUrls: ['video-upload.component.scss']
})

export class VideoUploadComponent {

    @Input()
    videoUrl: any;

    @Input()
    isEditMode: any = false;

    @Output()
    uploadedFile: EventEmitter<File> = new EventEmitter();

    error: string;

    constructor() {
    }

    handleFileInput(files: FileList) {
        if (files.length > 0) {
            const file = files[0];

            if (true) {

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (_event) => {
                    this.videoUrl = reader.result;
                }
                this.uploadedFile.emit(file);

            } else {
                this.error = "Only image file is allowed";
            }
        }
    }

    setVideoUrl(videoUrl) {
        this.videoUrl = videoUrl;
    }

}
