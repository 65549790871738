import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { UserAuthService } from '../service/auth.service';

@Injectable({
    providedIn: "root"
})
export class ModuleLoadGuard implements CanLoad {

    constructor(private auth: UserAuthService) { }

    canLoad(): boolean {
        if (!this.auth.isLoggedIn()) {
            //window.location.href = "http://localhost:5000/";
            return;
        }
        return true;
    }

}
