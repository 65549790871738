<div id="accordion">
    <div class="card">
        <div class="card-header" (click)="showHideBody()">
            <div class="row">
                <div class="col-11">
                    <ng-content select=".header"></ng-content>
                </div>
                <div style="float: right"><i class="fa" [ngClass]="idBodyVisible ? 'fa-chevron-right' : 'fa-chevron-down'"></i></div>
            </div>
        </div>

        <div *ngIf="idBodyVisible">
            <div class="card-body">
                <ng-content select=".body"></ng-content>
            </div>
        </div>
    </div>
</div>