<div class="form-group mb-0 mbl-admission" [formGroup]="group">
  <span class="has-float-label">
  <ng-select #select [addTag]="allowAdd ? onAdd : false" [formControlName]="controlName" 
  (change)="onChange($event)" 
  [loading]="loading" 
  placeholder="Select {{displayName}}">
    <ng-option *ngFor="let item of items" [value]="item.value">{{item.value}}</ng-option>
    <ng-template ng-footer-tmp>
      <label (click)="manage(); select.isOpen = false">
        <i class="fa fa-plus mr-1" aria-hidden="true"></i>Add
        {{ displayName }}
      </label>
    </ng-template>

    <ng-template ng-tag-tmp let-search="searchTerm">
      <i class="fa fa-plus mr-1 text-success" aria-hidden="true"></i>
      <b>Add</b> {{ search }}
    </ng-template>
  </ng-select>
  <label [ngClass]="{'required' : addAsterisk}">{{ label }} </label>
</span>

  <validation-message [control]="group.controls[controlName]" [message]="displayName" [isFormSubmitted]="isFormSubmitted">
  </validation-message>
</div>