import { Classroom } from "./classroom";

export class Timetable {
    weekDay: string;
    sessionNumber: number;
    subjectName: string;
    startTime: string;
    endTime: string;
    teacherName: string;
    classroom: Classroom;
}

export class TimetableTeacher {
    employeeProfileId: number;
    employeeId: number | string;
    fullName: string;
    department: string;
    designation: string;
    phonePrimary: string;
    email: string;
}

export class AcademicTimetable {
    timeTableId: number;
    classroom: any;
    weekDay: string;
    periodNumber: string;
    subjectId: string;
    subjectName: string;
    startTime: string;
    endTime: string;
    teacher: TimetableTeacher
}

export class PerioudWiseTimeTable {
    periodNumber: number;
    timetable: AcademicTimetable[]
}

export class AcademicTimeTableList {
    weekday: string;
    periodwiseTimetableList: PerioudWiseTimeTable[]
}

export class DigitalDiaryList {
    
}