import { Injectable } from '@angular/core';
import { TreeviewSelection, DefaultTreeviewI18n } from 'ngx-treeview';

@Injectable()
export class DropdownTreeviewSelectI18n extends DefaultTreeviewI18n {


    getText(selection: TreeviewSelection): string {
        if (selection.uncheckedItems.length === 0) {
            if(selection.checkedItems.length !== 0) {
                return 'All Selected';
            } else {
                return 'Select Exam';
            }
        }
        switch (selection.checkedItems.length) {
            case 0:
                return 'Select Exam';
            case 1:
                return selection.checkedItems[0].text;
            default:
                return `${selection.checkedItems.length}  selected`
        }
    }

    getFilterNoItemsFoundText(): string {
        return 'No examination found';
    }
}