import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Lookup, LookupCategory } from 'models';
import { LookupService } from '../../service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    templateUrl: './lookup.component.html',
    styleUrls: ['lookup.component.scss']
})

export class LookupComponent implements OnInit {

    lookupData: any[] = [];
    loading = false;
    categoryName: LookupCategory;
    displayName: LookupCategory;
    module: string;
    onClose: Subject<string[]> = new Subject();
    isUpdate: boolean;
    isAdd: boolean = false;
    addValue: string;

    constructor(
        public bsModalRef: BsModalRef,
        private lookupService: LookupService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit() {
        this.getList();
    }

    private getList() {
        this.spinner.show();
        this.lookupService.lookup(this.categoryName).subscribe(x => {
            this.spinner.hide();
            this.lookupData = x;
        }, (err) => {
            this.spinner.hide();
        })
    }

    delete(item: Lookup) {
        //const result = confirm("Are you sure, you want to delete?");
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            allowOutsideClick: false
        }).then((result) => {
            this.close();
            this.toastr.success('Record Deleted');
            if (result.isConfirmed) {
                this.lookupService.removeByParams({ lookupSetupId: item.lookupSetupId }).subscribe(() => {
                    this.lookupData = this.lookupData.filter(x => x.lookupSetupId !== item.lookupSetupId);
                    this.isUpdate = true;
                })

            }
        })
    }

    edit(item: Lookup) {
        item.newValue = item.value;
        item.editMode = true;
    }

    cancel(item: Lookup) {
        item.newValue = '';
        item.editMode = false
    }

    update = (item: Lookup) => {
        item.value = item.newValue;
        item.module = this.module;
        this.lookupService.updateLookUp(item).subscribe(() => {
            item.editMode = false;
            item.value = item.newValue;
            this.isUpdate = true;
        });
    }

    add = (addValue: any) => {
        const lookup = {
            value: addValue,
            fieldName: this.categoryName,
            lookupSetupId: 0,
            module: this.module
        }

        this.lookupService.addLookUp(lookup).subscribe((res) => {
            this.isAdd = false;
            this.isUpdate = true;
            this.addValue = '';
            this.getList();
        });
    }

    cancelAdd() {
        this.isAdd = false;
        this.addValue = '';
    }

    close() {
        if (this.isUpdate) {
            this.onClose.next(this.lookupData.map(x => x));
        }
        this.bsModalRef.hide();
    }

}
