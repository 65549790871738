import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges } from '@angular/core';
import { TreeviewConfig, TreeviewItem, TreeviewHelper, DropdownTreeviewComponent, TreeviewI18n } from 'ngx-treeview';
import { LookupService } from '../../service';
import { Category, Tree } from 'models';
import { CommonUtility } from '../../utilities';
import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';

@Component({
    selector: 'app-single-test',
    templateUrl: './single-test.component.html',
    styleUrls: ['single-test.component.scss'],
    providers: [
        { provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n }
    ]
})

export class SingleTestComponent implements OnInit, OnChanges {

    @Input()
    value = {
        exam: 0,
        test: 0
    };

    @Input()
    get classroomId(): number { return this._classroomId; }
    set classroomId(classroomId: number) {
        this._classroomId = classroomId;
        this.getData();
    }
    private _classroomId = null;

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter();

    @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;

    // selectedItem : 

    config = TreeviewConfig.create({
        hasAllCheckBox: false,
        hasCollapseExpand: false,
        hasFilter: true,
        maxHeight: 350,

    });

    filterText: string;
    items: any[];
    private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;


    constructor(public i18n: TreeviewI18n, private lookupService: LookupService) {
        this.dropdownTreeviewSelectI18n = i18n as DropdownTreeviewSelectI18n;
    }

    ngOnInit() { }

    ngOnChanges(): void {
        this.updateSelectedItem();
    }

    private getData() {
        if (this.classroomId) {
            this.lookupService.getExamination(this.classroomId).subscribe(result => {
                if (result) {
                    this.items = this.structureTree(result);
                } else {
                    this.items = [];
                }
            }, error => {
                this.items = [];
            })
        } else {
            this.items = [];
        }
    }

    private structureTree(result: any[], textBy: string = 'examName', valueBy: string = 'examId'): TreeviewItem[] {
        return result.map(item => {
            const parent = new TreeviewItem({
                text: item[textBy],
                value: item[valueBy],
                children: [],
                checked: false
            });
            if (item.tests && item.tests.length) {
                parent.children = this.structureTree(item.tests, 'testName', 'testId');
            }
            if (valueBy === 'examId') {
                parent['examId'] = item.examId;
            } else if (valueBy === 'testId') {
                parent['testId'] = item.testId;
            }
            return parent;
        })
    }

    select(item: TreeviewItem): void {
        if (!item.children) {
            this.selectItem(item);
        }
    }

    private updateSelectedItem(): void {
        if (CommonUtility.isNotNull(this.items)) {
            const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
            this.selectItem(selectedItem);
        }
    }

    selectItem(item: TreeviewItem): void {
        if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
            this.dropdownTreeviewSelectI18n.selectedItem = item;
            if (this.dropdownTreeviewComponent) {
                this.dropdownTreeviewComponent.onSelectedChange([item]);
            }
            if (item) {
                if (this.value !== item.value) {
                    this.valueChange.emit(item);
                }
            }
        }
    }
}
