import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges } from '@angular/core';
import { TreeviewConfig, TreeviewItem, TreeviewHelper, DropdownTreeviewComponent, TreeviewI18n } from 'ngx-treeview';
import { LookupService } from '../../service';
import { Category, Tree } from 'models';
import { CommonUtility } from '../../utilities';
import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';

@Component({
    selector: 'app-single-skill',
    templateUrl: './single-skill.component.html',
    styleUrls: ['single-skill.component.scss'],
    providers: [
        { provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n }
    ]
})

export class SingleSkillComponent implements OnInit, OnChanges {

    @Input()
    value: number;

    @Input()
    get skills(): Category[] { return this._skills; }
    set skills(skills: Category[]) {
        this._skills = skills;
        this.items = this.structureTree(skills);
    }
    private _skills = null;

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter();

    @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;

    // selectedItem : 

    config = TreeviewConfig.create({
        hasAllCheckBox: false,
        hasCollapseExpand: false,
        hasFilter: true,
        maxHeight: 350,

    });

    filterText: string;
    items: TreeviewItem[];
    private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;


    constructor(public i18n: TreeviewI18n, private lookupService: LookupService) {
        this.dropdownTreeviewSelectI18n = i18n as DropdownTreeviewSelectI18n;
    }

    ngOnInit() { }

    ngOnChanges(): void {
        this.updateSelectedItem();
    }

    private structureTree(result: any[], textBy: string = 'categoryName', valueBy: string = 'categoryId'): TreeviewItem[] {
        return result.map(item => {
            const parent = new TreeviewItem({
                text: item[textBy],
                value: item[valueBy],
                children: [],
                checked: false
            });
            if (item.subCategoriesList && item.subCategoriesList.length) {
                parent.children = this.structureTree(item.subCategoriesList, 'subCategoryName', 'subCategoryId');
            } else if (item.skillList && item.skillList.length) {
                parent.children = this.structureTree(item.skillList, 'skillName', 'skillId');
            }

            return parent;
        })
    }

    select(item: TreeviewItem): void {
        if (!item.children) {
            this.selectItem(item);
        }
    }

    private updateSelectedItem(): void {
        if (CommonUtility.isNotNull(this.items)) {
            const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
            this.selectItem(selectedItem);
        }
    }

    selectItem(item: TreeviewItem): void {
        if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
            this.dropdownTreeviewSelectI18n.selectedItem = item;
            if (this.dropdownTreeviewComponent) {
                this.dropdownTreeviewComponent.onSelectedChange([item]);
            }
            if (item) {
                if (this.value !== item.value) {
                    this.value = item.value;
                    this.valueChange.emit(item.value);
                }
            }
        }
    }
}
