import { Injectable } from '@angular/core';
import { TreeviewSelection, DefaultTreeviewI18n } from 'ngx-treeview';

@Injectable()
export class DropdownTreeviewSelectI18n extends DefaultTreeviewI18n {


    getText(selection: TreeviewSelection): string {
        if (selection.uncheckedItems.length === 0 && selection.checkedItems.length) {
            return 'All Selected';
        }

        switch (selection.checkedItems.length) {
            case 0:
                return 'Select';
            case 1:
                return selection.checkedItems[0].text;
            default:
                return `${selection.checkedItems.length}  selected`
        }
    }

    getFilterNoItemsFoundText(): string {
        return 'No Skills found';
    }
}