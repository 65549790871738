import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges } from '@angular/core';
import { TreeviewConfig, TreeviewItem, TreeviewHelper, DropdownTreeviewComponent, TreeviewI18n } from 'ngx-treeview';
import { LookupService } from '../../service';
import { CommonUtility } from '../../utilities';
import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';

@Component({
    selector: 'app-multiple-test',
    templateUrl: './multiple-test.component.html',
    styleUrls: ['multiple-test.component.scss'],
    providers: [
        { provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n }
    ]
})

export class MultipleTestComponent implements OnInit, OnChanges {

    @Input()
    value = {
        exams: [],
        tests: []
    };

    @Input()
    get classroomId(): number { return this._classroomId; }
    set classroomId(classroomId: number) {
        this._classroomId = classroomId;
        this.getData();
    }
    private _classroomId = null;

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter();

    @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;

    // selectedItem : 

    config = TreeviewConfig.create({
        hasAllCheckBox: false,
        hasCollapseExpand: true,
        hasFilter: true,
        maxHeight: 350
    });

    filterText: string;
    items: TreeviewItem[];


    constructor(private lookupService: LookupService) {

    }

    ngOnInit() { }

    ngOnChanges(): void {
        this.updateSelectedItem();
    }

    private getData() {
        this.lookupService.getExamination(this.classroomId).subscribe(result => {
            this.items = this.structureTree(result);
        }, error => {
            this.items = [];
        })
    }

    private structureTree(result: any[], textBy: string = 'examName', valueBy: string = 'examId'): TreeviewItem[] {
        return result.map(item => {
            const parent = new TreeviewItem({
                text: item[textBy],
                value: item[valueBy],
                children: [],
                checked: false
            });
            if (item.tests && item.tests.length > 0) {
                parent.children = this.structureTree(item.tests, 'testName', 'testId');
            }

            return parent;
        })
    }

    onSelectedChange(downlineItems: number[]): void {
        const value = {
            exams: [],
            tests: []
        };
        if (Array.isArray(this.items)) {
            if (downlineItems && downlineItems.length > 0) {
                for (const item of this.items) {
                    if (item.checked !== false) {
                        item.disabled = false;
                        value.exams.push(item.value);
                        if (Array.isArray(item.children)) {
                            for (const child of item.children) {
                                if (child.checked === true) {
                                    value.tests.push(child.value);
                                }
                            }
                        }
                    } else {
                        item.disabled = true;
                    }
                }
            } else {
                for (const item of this.items) {
                    item.disabled = false;
                }
            }
        }
        if (downlineItems) {
            if (this.value !== value) {
                this.value = value;
                this.valueChange.emit(value);
            }
        }
    }

    private updateSelectedItem(): void {
        if (CommonUtility.isNotNull(this.items)) {
            const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
            this.selectItem(selectedItem);
        }
    }

    selectItem(item: TreeviewItem): void {
        if (item) {
            if (this.value !== item.value) {
                this.value = item.value;
                this.valueChange.emit(item.value);
            }
        }
    }
}
