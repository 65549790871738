export class AssessmentSkill {
    skillId: number;
    classroomId: number;
    subjectId: number;
    area: string;
    categoryId: number;
    categoryName: string;
    subCategoryId: number;
    subCategoryName: string;
    skillName: string;
    displayOrder: number;
    isActive: boolean;
}
