import { StudentMapping } from './student';

export class BankAccount {
    bankAccountId: number;
    accountNickName: string;
    bankName: string;
    accountNumber: number;
    accountStartDate: string;
    initialBalance: number;
    ifscCode: string
}

export class Payment {
    studentFeeId: number;
    feeHead: string;
    feeTerm: string;
    paymentAmount: number;
    controlNumber: number;
}

export class FeePayment {
    invoiceNumber: number;
    controlNumber: number;
    paymentDate: string;
    paymentMethod: string;
    paymentDescription: string;
    checkNbr: string;
    paymentReferenceNbr: string;
    transactionStatus: string;
    clearanceStatus: string;
    paymentClearedDate: string;
    paymentSource: string;
    bankAccount: BankAccount;
    student: StudentMapping;
    paymentsList: Payment[];
    paidAmount: number;
    receivedBy?: string;
}