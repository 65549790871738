import { AuditFields } from './common';

export class Book {
    bookId: number;
    libraryCode: string;
    isbn: string;
    title: string;
    author: string;
    category: string;
    description?: any;
    subject?: any;
    language?: any;
    publisher?: any;
    publishedYear: string;
    edition: string;
    readingTimeInMin?: any;
    supplier?: any;
    memberType: string;
    isFinable: string;
    price: number;
    pageCount: number;
    remarks?: any;
    actualQty: number;
    availableQty?: number;
    tags?: any;
    imageUrl?: any;
    auditFields: AuditFields;
}


export class BookBasicInfo {
    bookId: number;
    libraryCode: string;
    isbn: string;
    title: string;
    author: string;
    category: string;
    recordStatus: string;
}


export class BookSummary {
    totalQty: number;
    availableQty: number;
    totalTransactions: number;
    booksReturningInAWeek: number;
    readingTimeInMinutes?: any;
}

export class BookSearch {

    bookId: number;
    bookBasicInfo: BookBasicInfo;
    description?: any;
    subject: string;
    language?: any;
    publisher: string;
    publishedYear: string;
    edition?: any;
    readingTimeInMin?: any;
    supplier: string;
    memberType: string;
    isFinable?: boolean;
    price: number;
    pageCount: number;
    remarks?: any;
    actualQty: number;
    availableQty: number;
    tags?: any;
    recordStatus: string;
    auditFields: AuditFields;
}