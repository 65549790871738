import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges } from '@angular/core';
import { TreeviewConfig, TreeviewItem, TreeviewHelper, DropdownTreeviewComponent, TreeviewI18n } from 'ngx-treeview';
import { LookupService } from '../../service';
import { Tree } from 'models';
import { CommonUtility } from '../../utilities';
import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';

@Component({
    selector: 'app-multiple-classroom',
    templateUrl: './multiple-classroom.component.html',
    styleUrls: ['multiple-classroom.component.scss'],
    providers: [
        { provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n }
    ]
})

export class MultipleClassroomComponent implements OnInit, OnChanges {
    @Input()
    value: number[] = [];

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter();
    valueChanged = false;
    valuePresent = false;
    treeClosed = false;

    @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;

    config = TreeviewConfig.create({
        hasAllCheckBox: true,
        hasCollapseExpand: true,
        hasFilter: true,
        maxHeight: 350,
    });

    filterText: string;
    items: TreeviewItem[];

    constructor(private lookupService: LookupService) {
    }

    ngOnInit() {
        this.getData();
    }

    ngOnChanges(): void {
        this.updateSelectedItem();
    }

    private getData() {
        this.lookupService.classroom().subscribe(result => {
            this.items = this.structureTree(result);
        });
    }

    private structureTree(result: Tree[]): TreeviewItem[] {
        return result.map(item => {
            const parent = new TreeviewItem({
                text: item.text,
                value: item.value,
                children: [],
                checked: false
            });
            if (this.value && this.value.length > 0) {
                for (let i = 0; i < this.value.length; i += 1) {
                    if (this.value[i] == parent.value) {
                        parent.checked = true;
                        break;
                    }
                }
            }
            if (item.children) {
                parent.children = this.structureTree(item.children);
            }

            return parent;
        })
    }

    onSelectedChange(downlineItems: number[]): void {
        if (downlineItems) {
            if (this.value !== downlineItems) {
                this.value = downlineItems;
                this.valueChange.emit(downlineItems);
                if (downlineItems.length > 0) {
                    this.valueChanged = true;
                    this.treeClosed = false;
                    this.valuePresent = true;
                    setTimeout(() => {
                        this.valueChanged = false;
                    }, 500);
                } else {
                    this.valueChanged = false;
                    this.valuePresent = false;
                }
            }
        }
    }

    private updateSelectedItem(): void {
        if (CommonUtility.isNotNull(this.items)) {
            const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
            this.selectItem(selectedItem);
        }
    }

    private selectItem(item: TreeviewItem): void {
        if (item) {
            if (this.value !== item.value) {
                this.value = item.value;
                this.valueChange.emit(item.value);
            }
        }
    }

    closeTreeView() {
        this.treeClosed = true;
    }
}
