import { EmployeeBasicInfo } from "./employee";

export class LeaveRequest {
    leaveRequestId: number;
    employeeRequestId: number;
    employeeBasicInfoDTO: EmployeeBasicInfo;
    leaveType: string;
    description: string;
    startDate: string;
    endDate: string;
    requestStatus: string;
    comments: string;
    actionTakenBy: string;
    actionTakenTs: string;
    isActive: string;
}

export class LeavePolicy {
    leaveSetupId: number;
    designation: string;
    leaveType: string;
    frequency: string;
    recurringMonth: number;
    carryFarwardInd: boolean;
    expiryMonth: number;
    numberOfLeaves: number;
}

export class LeaveTransaction {
    id: number;
    leaveType: string;
    transactionDate: string;
    credit: number;
    debit: number;
    carryFarwardInd: boolean;
    expiryMonth: number;
    numberOfLeaves: number;
    remarks: string;
}
