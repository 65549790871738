import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,
    shifts: `${apiPath}/attendance/shifts`,
    classrooms: `${apiPath}/attendance/shifts/classrooms`,
    mapClassrooms: `${apiPath}/attendance/shifts/$shiftId/mapClassrooms`,
    students: `${apiPath}/attendance/shifts/student-mapping`,
    mapStudents: `${apiPath}/attendance/shifts/$shiftId/mapStudents`,
    employees: `${apiPath}/attendance/shifts/employees`,
    mapEmployees: `${apiPath}/attendance/shifts/$shiftId/mapEmployees`,
    studentAttendance: `${apiPath}/attendance/students`,
    employeeAttendance: `${apiPath}/attendance/employees`,
    recordAttendance: `${apiPath}/attendance/`,
    devices: `${apiPath}/attendance/biometric/devices`,
    shiftMapping: `${apiPath}/attendance/biometric/devices/shiftMapping`,
    report: `${apiPath}/attendance/report`,
    punchReport: `${apiPath}/attendance/biometric/punch-report`,
    biometricSetup: `${apiPath}/attendance/biometric/setup`,
    biometricUserMapping: `${apiPath}/attendance/biometric-mapping`,
    studentSummary: `${apiPath}/attendance/nonBiometricStudents/monthly`,
    autoCalculate: `${apiPath}/attendance/biometricStudents/monthly`,
    saveAttedanceSummary : `${apiPath}/attendance/students/monthly`
};

export const PublicAPI = [];
