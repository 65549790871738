import {Component, Injector, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssignedSchool, } from 'models';
import {AppService,  UserAuthService} from '../../service';
import {CommonConstant} from "../../constants";
import {ActivatedRoute, Router} from "@angular/router";
import { UploadServiceSchoolChangeService } from '../../service/upload-service-school-change.service';
import { Images } from "@school-core";
@Component({
    templateUrl: './school-change-popup.component.html',
    styleUrls: ['school-change-popup.component.scss']
})

export class SchoolChangePopupComponent implements OnInit {

    schoolList: AssignedSchool[];
    selectedSchool: string;
    schoolName: any;
    isSelectSchool = false;
    studentList: any;
    studentDataList: any;
    selectedStudent: string;
    isSelectStudent = false;
    studentSchoolName: string;
    selectedStudentData: any;
    profilePics: any ;
    selectedSchoolData: any;
   // selectedStudentData: any;


    constructor(public bsModalRef: BsModalRef, private appService: AppService,
                private userAuthService: UserAuthService,
                private userServive: UserAuthService,
                private uploadServiceSchoolChangeService: UploadServiceSchoolChangeService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private injector: Injector)
    {


    }

    ngOnInit() {
        if(window.sessionStorage.getItem('appName') === 'SmartParent'){
            this.selectedStudent = this.userAuthService.getStudentName();
        } else {
            this.selectedSchool = this.userAuthService.getOrgCodes();
        }
        this.getList();
        this.getStudentData();
    }



    private getList() {
        this.appService.getUserAccess(window.sessionStorage.getItem('userName')).subscribe(result => {
            this.schoolList = result;
            console.log("school list", this.schoolList);


            if (this.schoolList && this.schoolList.length > 0) {
                this.schoolList.forEach(item => {
                    const params = {
                        module: 'School',
                        fileName: 'logo',
                        contentType: 'image/jpeg'
                    }

                    // const auth = this.injector.get<UploadService>(UploadService);
                    // auth.getDocument(params).subscribe((data) => {
                    //     if (data.status != 406) {
                    //         item.imageSrc = data ? data.URL : Images.school;
                    //     } else {
                    //         item.imageSrc = Images.school;
                    //     }
                    // }, err => {
                    //     item.imageSrc = Images.school;
                    // });

                    // this.uploadServiceSchoolChangeService.getDocument(params).subscribe((data) => {
                    //     if (data.status != 406) {
                    //         item.imageSrc = data ? data.URL : Images.school;
                    //     } else {
                    //         item.imageSrc = Images.school;
                    //     }
                    // }, err => {
                    //     item.imageSrc = Images.school;
                    // });
                });
            }
        });


    }

    selectSchool(item: AssignedSchool) {
        this.selectedSchoolData = item;
        this.isSelectSchool = true;
        this.isSelectStudent = false;
        this.selectedStudent = null;
        this.selectedSchool = item['orgCode'];
        this.schoolName = item['schoolName'];
        this.userAuthService.saveEmployeeName(item['employeeName']);
        this.userAuthService.saveEmployeeId(item['userUniqueId']);
        this.selectedStudentData = [];
    }

    close() {
        this.bsModalRef.hide();
    }

    change() {
        if(this.isSelectSchool) {
            this.userServive.saveEmployeeName(this.selectedSchoolData?.employeeName);
            this.userServive.saveEmployeeId(this.selectedSchoolData?.userUniqueId);
            this.userServive.saveSchoolName(this.selectedSchoolData?.schoolName);
            this.userServive.saveOrgCode(this.selectedSchoolData?.orgCode);
            window.sessionStorage.setItem('schoolName', this.selectedSchoolData?.schoolName);

           // window.location.reload();

            if(window.sessionStorage.getItem('appName') === 'Spectrum'){
                window.location.href = window.location.href + '?change=true';
                // window.location.reload();
            }else {
                setTimeout(() => {
                    this.userServive.saveAppName("Spectrum");
                    this.userAuthService.saveUserName(this.selectedSchoolData?.username);
                    // window.location.reload();
                    window.location.href = `${window.location.origin}/validate?token=${window.sessionStorage.getItem(CommonConstant.token)}&code=${this.selectedSchoolData?.orgCode}&appName=Spectrum&employeeId=${this.selectedSchoolData?.userUniqueId}&employeeName=${this.selectedSchoolData?.employeeName}&schoolName=${this.selectedSchoolData?.schoolName}&userName=${window.sessionStorage.getItem('userName')}`;
                }, 500);
            }
        } else {
            window.sessionStorage.removeItem('code');
            window.sessionStorage.removeItem('schoolName');
            window.sessionStorage.removeItem('school');
            window.sessionStorage.removeItem('studentProfileId');
            window.sessionStorage.removeItem('studentName');
            window.sessionStorage.removeItem('appName');

            window.sessionStorage.setItem('studentProfileId', this.selectedStudentData?.userUniqueId);
            this.userAuthService.saveStudentProfileId(this.selectedStudentData?.userUniqueId);
            this.userAuthService.saveUserName(this.selectedStudentData?.username);
            this.userAuthService.saveStudentName(this.selectedStudentData?.studentName);
            this.userAuthService.saveOrgCode(this.selectedStudentData.orgCode);
            this.userServive.saveSchoolName(this.selectedStudentData?.schoolName);
            window.sessionStorage.setItem('schoolName', this.selectedStudentData?.schoolName);
            if(window.sessionStorage.getItem('appName') === 'SmartParent'){
                window.location.href = window.location.href + '?change=true';
                //  window.location.reload();
            } else {
                setTimeout(() => {
                    this.userAuthService.saveAppName("SmartParent");
                    // window.location.reload();
                    window.location.href = `${window.location.origin}/validate?token=${window.sessionStorage.getItem(CommonConstant.token)}&code=${this.selectedStudentData.orgCode}&studentProfileId=${this.selectedStudentData.userUniqueId}&studentName=${this.selectedStudentData.studentName}&schoolName=${this.selectedStudentData.schoolName}&appName=${window.sessionStorage.getItem('appName')}&id=${this.selectedStudentData.userUniqueId}&userName=${ this.selectedStudentData.username}`;
                }, 500);
            }

        }
    }

    getStudentData(){
        this.appService.getUserAccessForParent(window.sessionStorage.getItem('userName')).subscribe((res : any) => {
            if(res) {
                this.studentDataList = res;
                if (this.studentDataList && this.studentDataList.length > 0) {
                    this.studentDataList.forEach(item => {
                        const params = {
                            module: 'School',
                            fileName: 'logo',
                            contentType: 'image/jpeg'
                        }
                        // this.uploadServiceSchoolChangeService.getDocument(params).subscribe((data) => {
                        //     if (data.status != 406) {
                        //         item.imageSrc = data ? data.URL : Images.school;
                        //     } else {
                        //         item.imageSrc = Images.school;
                        //     }
                        // }, err => {
                        //     item.imageSrc = Images.school;
                        // });
                    });
                }

            }
        });
    }

    selectStudent(student: any) {

        this.isSelectStudent = true;
        this.isSelectSchool = false;
        this.selectedStudent = student.studentName;
        this.selectedSchool = null;
        this.selectedStudentData = student;

    }

    enableSubmit() {
        if(this.isSelectSchool) {
            return false;
        } else if (this.isSelectStudent) {
            return false;
        } else {
            return true;
        }
    }
}
