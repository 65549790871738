import { NotificationType, ReportCategory } from "models";

export class Template {
    body: string;
    category: ReportCategory;
    id: number;
    subject: string;
    type: NotificationType;
    dltTempalteId:string;
    templateName:string;
    categoryDisplayName:string;
}
