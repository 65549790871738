export class Assignment {
    assignmentId: string;
    assignedDate: string
    academicYear: string;
    classroomId: number;
    subjectId: number;
    chapterId: number;
    title: string;
    description: string;
    status: string;
    maxScore: number;
    studentWorkStatus: string;
    dueDate: string;
}


export class Chapter {
    chapterId: number;
    chapterName: string;
    classroomId: number;
    desciption: string;
    subjectId: number;
}

export class Topic {
    topicId: number;
    chapterId: number;
    topicName: string;
    desciption: string;
}

export class AssignmentStudentProfile {
    studentProfileId: string;
    aadharNbr: string;
    admissionNumber: string;
    bloodGroup: string;
    classroom: AssignmentClassRoom;
    dateOfBirth: string;
    feeCategory: string;
    gender: string;
    rollNbr: string;
    firstName: string;
    lastName: string;
    selected: boolean;
}

export class AssignmentClassRoom {
    classroomId: number;
    classroomName: string;
    degreeId: number;
    degreeName: string;
    deptId: number;
    deptName: string;
    courseId: number;
    courseName: string;
    displayOrder: string;
    isActive: string;
    defaultClassroomSetupId: string;
    academicYear: string;

}

export class AssignmentStudentMapping {
    studentAssignmentId: number;
    assignment: Assignment;
    score: string;
    status: string;
    studentAnswer: string;
    studentProfile: AssignmentStudentProfile;
    teacherRemarks: string;
}

export class StudentWorkAccordion {
    due: boolean;
    submitted: boolean;
    reviewed: boolean;
}

export class FileDocument {
    url: string;
    type: string;
    name: string
}


