import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['image-upload.component.scss']
})

export class ImageUploadComponent {

    @Input()
    imageUrl: any;

    @Input()
    isEditMode: any = false;

    @Output()
    uploadedFile: EventEmitter<File> = new EventEmitter();

    error: string;

    constructor() {
    }

    handleFileInput(files: FileList) {
        if (files.length > 0) {
            const file = files[0];

            if (file.type.includes('image')) {

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (_event) => {
                    this.imageUrl = reader.result;
                }
                this.uploadedFile.emit(file);

            } else {
                this.error = "Only image file is allowed";
            }
        }
    }

}
