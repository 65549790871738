import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss']
})
export class PreviewComponent {

    @Input() templateData: any[];
    @Output() selectedTemplate = new EventEmitter();
    @Output() download = new EventEmitter();

    constructor() { }

    onCardClick = (index) => {
        this.templateData.forEach(item => item.active = false);
        this.templateData[index].active = true;
        this.selectedTemplate.emit({
            id: this.templateData[index].id,
            category: this.templateData[index].category,
            templateName: this.templateData[index].templateName,
            displayName: this.templateData[index].displayName,
            headerLine1: this.templateData[index].headerLine1,
            headerLine2: this.templateData[index].headerLine2,
            headerLine3: this.templateData[index].headerLine3,
            description: this.templateData[index].description,
            isDualTermReportCard: this.templateData[index].isDualTermReportCard
        });
    }
}