import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { APIConstant } from "../constant";
import { Classroom, Course, Degree, Department, School } from "models";
import { catchError, mergeMap } from "rxjs/operators";
import { Images } from "@attendance-core";

@Injectable({
    providedIn: "root",
})
export class SchoolService extends CRUDService<any> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.school + "/");
    }

    getSchoolProfileType(): Observable<any> {
        return this.baseService.get(`${APIConstant.profileType}`);
    }

    getCourses(showInactive = false, id?: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            departmentId: id,
            showInactive
        });
        return this.baseService.get(`${APIConstant.courses}?${params}`);
    }
    changeCourseOrder(id: number, from: number, to: number, deptId?: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            deptId: deptId,
            courseId: id,
            fromOrder: from,
            toOrder: to,
        });
        return this.baseService.put(`${APIConstant.courses}/changeOrder?${params}`, null);
    }

    addClassroom(classroomData: Course): Observable<any> {
        return this.baseService.post(`${APIConstant.courses}`, classroomData);
    }

    updateClassroom(classroomData: Course): Observable<any> {
        return this.baseService.put(`${APIConstant.courses}`, classroomData);
    }

    deleteClassroom(id: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            courseId: id,
        });
        return this.baseService.delete(`${APIConstant.courses}?${params}`);
    }

    getSection(showInactive = false, id?: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            courseId: id,
            showInactive
        });
        return this.baseService.get(`${APIConstant.classrooms}?${params}`);
    }

    getAllClassrooms(): Observable<any> {
        return this.baseService.get(`${APIConstant.allClassrooms}?`);
    }

    changeSectionOrder(courseId: number, id: number, from: number, to: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            courseId: courseId,
            classroomId: id,
            fromOrder: from,
            toOrder: to,
        });
        return this.baseService.put(`${APIConstant.classrooms}/changeOrder?${params}`, null);
    }

    addSection(classroomData: Classroom): Observable<any> {
        return this.baseService.post(`${APIConstant.classrooms}`, classroomData);
    }

    updateSection(classroomData: Classroom): Observable<any> {
        return this.baseService.put(`${APIConstant.classrooms}`, classroomData);
    }

    deleteSection(classroomId: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            classroomId: classroomId,
        });
        return this.baseService.delete(`${APIConstant.classrooms}?${params}`);
    }
    getDegrees(showInactive = false): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            showInactive
        });
        return this.baseService.get(`${APIConstant.degrees}?${params}`);
    }
    changeDegreeOrder(id: number, from: number, to: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            degreeId: id,
            fromOrder: from,
            toOrder: to,
        });
        return this.baseService.put(`${APIConstant.degrees}/changeOrder?${params}`, null);
    }

    addDegrees(data: Degree): Observable<any> {
        return this.baseService.post(`${APIConstant.degrees}`, data);
    }

    updateDegrees(data: Degree): Observable<any> {
        return this.baseService.put(`${APIConstant.degrees}`, data);
    }

    deleteDegrees(id: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            degreeId: id,
        });
        return this.baseService.delete(`${APIConstant.degrees}?${params}`);
    }

    getDepartment(showInactive = false, id: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            degreeId: id,
            showInactive
        });
        return this.baseService.get(`${APIConstant.departments}?${params}`);
    }

    changeDepartmentOrder(degreeId: number, id: number, from: number, to: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            degreeId: degreeId,
            deptId: id,
            fromOrder: from,
            toOrder: to,
        });
        return this.baseService.put(`${APIConstant.departments}/changeOrder?${params}`, null);
    }
    addDepartment(data: Department): Observable<any> {
        return this.baseService.post(`${APIConstant.departments}`, data);
    }

    udpateDepartment(data: Department): Observable<any> {
        return this.baseService.put(`${APIConstant.departments}`, data);
    }

    deleteDepartment(id: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            deptId: id,
        });
        return this.baseService.delete(`${APIConstant.departments}?${params}`);
    }
    getSchoolprofile(): Observable<School> {
        return this.baseService.get(`${APIConstant.school}/profile`);
    }
    updateSchoolprofile(data: School): Observable<any> {
        return this.baseService.put(`${APIConstant.school}/profile`, data);
    }

    getImage(): Observable<string> {
        return this.baseService.getWithResponseType<string>(`${APIConstant.school}/logo`, "text").pipe(catchError(() => of(Images.user)));
    }

    getSchoolBranches(){
        return this.baseService.get("https://schooladminportal.com/spectrum-api/school/branches?groupCode=1012")
    }

    uploadImage(file: File): Observable<any> {
        const queryParams = CommonUtility.convertObjectToParams({
            contentType: file.type,
        });
        return this.baseService.put(`${APIConstant.school}/logo?${queryParams}`, null).pipe(
            mergeMap((response: any) => {
                return this.baseService.put(response.URL, file);
            })
        );
    }

    addYear(data: any): Observable<any> {
        return this.baseService.post<any>(APIConstant.courses, data);
    }

    updateYear(id: number, data: any): Observable<any> {
        let url: string = APIConstant.courses;
        if (id) {
            url += `${id}`;
        }
        return this.baseService.put<any>(url, data);
    }
    deleteYear(id: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            courseId: id,
        });
        return this.baseService.delete(`${APIConstant.courses}?${params}`);
    }

    getCurrentAcademicYear() {
        return this.baseService.get(`${APIConstant.currentAcademicYear}`);
    }

    copyFeeSetupFromRefClassroom(destinationClassroomId: any, referenceClassroomId: any, academicYear: any) {
        const params = CommonUtility.convertObjectToParams({
            destinationClassroomId: destinationClassroomId,
            referenceClassroomId: referenceClassroomId,
            academicYear: academicYear
        });
        return this.baseService.post(`${APIConstant.copyFeeFromRefClassroom}?${params}`, null);
    }

    copyShiftSetupFromRefClassroom(destinationClassroomId: any, referenceClassroomId: any, academicYear: any) {
        const params = CommonUtility.convertObjectToParams({
            destinationClassroomId: destinationClassroomId,
            referenceClassroomId: referenceClassroomId,
            academicYear: academicYear
        });
        return this.baseService.post(`${APIConstant.copyShiftFromRefClassroom}?${params}`, null);
    }

    copySubjectSetupFromRefClassroom(destinationClassroomId: any, referenceClassroomId: any, academicYear: any) {
        const params = CommonUtility.convertObjectToParams({
            destinationClassroomId: destinationClassroomId,
            referenceClassroomId: referenceClassroomId,
            academicYear: academicYear
        });
        return this.baseService.post(`${APIConstant.copySubjectFromRefClassroom}?${params}`, null);
    }

    copySkillSetupFromRefClassroom(destinationClassroomId: any, referenceClassroomId: any, academicYear: any) {
        const params = CommonUtility.convertObjectToParams({
            destinationClassroomId: destinationClassroomId,
            referenceClassroomId: referenceClassroomId,
            academicYear: academicYear
        });
        return this.baseService.post(`${APIConstant.copySkillFromRefClassroom}?${params}`, null);
    }

    copyExamSetupFromRefClassroom(destinationClassroomId: any, referenceClassroomId: any, academicYear: any) {
        const params = CommonUtility.convertObjectToParams({
            destinationClassroomId: destinationClassroomId,
            referenceClassroomId: referenceClassroomId,
            academicYear: academicYear
        });
        return this.baseService.post(`${APIConstant.copyExamFromRefClassroom}?${params}`, null);
    }

    copyReportCardSetupFromRefClassroom(destinationClassroomId: any, referenceClassroomId: any, academicYear: any) {
        const params = CommonUtility.convertObjectToParams({
            destinationClassroomId: destinationClassroomId,
            referenceClassroomId: referenceClassroomId,
            academicYear: academicYear
        });
        return this.baseService.post(`${APIConstant.copyReportCardFromRefClassroom}?${params}`, null);
    }

    getDefaultCourses()
    {
        return this.baseService.get(`${APIConstant.defaultCourses}`);
    }
}
