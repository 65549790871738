import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[focusFirstInvalidField]'
})
export class FocusFirstInvalidFieldDirective {

    constructor(private el: ElementRef) { }

    scrollTo(el: Element): void {
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    }



    @HostListener('submit', ['$event'])
    onFormSubmit() {
        const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
        if (invalidElements.length > 0) {
            this.scrollTo(invalidElements[0]);
        }
    }
}
