<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange">
    <div class="text-nowrap row-item" [ngClass]="{'active' : item.value === value}">
        <i class="fa" [ngClass]="item.collapsed ? 'fa-angle-right' : 'fa-angle-down'" *ngIf="item.children"
            (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
        </i>
        <label class="form-check-label" (click)="select(item)">{{ item.text }}</label>
    </div>
</ng-template>
<ng-template #headerTemplate let-config="config" let-item="item" let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange" let-onFilterTextChange="onFilterTextChange">
    <div *ngIf="config.hasFilter" class="row row-filter">
        <div class="col-12">
            <input class="form-control" type="text" [(ngModel)]="filterText"
                (ngModelChange)="onFilterTextChange($event)" placeholder="Search" />
        </div>
    </div>
    <div *ngIf="config.hasDivider" class="dropdown-divider"></div>
</ng-template>
<ngx-dropdown-treeview [config]="config" [headerTemplate]="headerTemplate" [items]="items"
    [itemTemplate]="itemTemplate">
</ngx-dropdown-treeview>