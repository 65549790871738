import { AuditFields, APIRequest } from "./common";

export class LookupRequest extends APIRequest {
    lookup: Lookup;
}

export class Lookup {
    lookupId: number;
    libraryCode: string;
    category: string;
    value: string;
    recordStatus: string;
    auditFields: AuditFields;
    fieldName: string;
    lookupSetupId: number;
    module: string;

    // UI Field
    editMode: boolean;
    newValue?: string;
}

export class Tree {
    children: Tree[];
    text: string;
    value: number;
    parent?: string;
}

