export class FeeHeadObj {
    feeHeadSetupId: number;
    headName: string;
    isActive: string;
    termsList: any[]
    transportFlag: string;
}

export class FeeTerm {
    dueDate: string;
    feeHeadSetupId: number;
    feeTermSetupId: number;
    isActive: string;
    termName: string;
}

export class FeeItem {
    academicYear: string;
    classroomId: number;
    concessionAmount: number;
    concessionCode: string;
    dueAmount: number;
    dueDate: string;
    feeAmount: number;
    feeHead: FeeHeadObj
    feeTerm: FeeTerm;
    finalAmount: number;
    overdueAmount: number;
    priority: number;
    refFeeSetupId: number;
    remarks: string;
    student: any
    studentFeeId: number;
}

export class FeeTermSetup {
    dueDate: string;
    feeAmount: number;
    feeHeadSetupId: number;
    feeTermSetupId: number;
    isActive: string;
    termName: string;
}

export class FeeItemSetup {
    academicYear: string;
    classroomId: number;
    dueDate: string;
    feeAmount: number;
    feeCategory: string;
    feeHead: FeeHeadObj;
    feeSetupId: number;
    feeTerm: FeeTermSetup;
    isSelected?: boolean;
}

export class FeeSetup {
    feeHead: FeeHeadObj;
    termList: FeeItemSetup[];
    allRowsSelected?: boolean;
}

export class FeeHeadItems {
    feeHead: string;
    feeItemList: FeeItem[];
}

export class Fee {
    academicYear: string;
    finalAmount: number;
    dueAmount: number;
    overdueAmount: number;
    feeHeadList: FeeHeadItems[]
}

export class FeeDetails {
    studentFeeId: number;
    feeAmount: number;
    concessionAmount: number;
    concessionCode: string;
    finalAmount: number;
    dueAmount: number;
    overdueAmount: number;
    dueDate: string;
    headName?: string;
}

export class FeeTermDrp extends FeeTerm {
    feeDetails?: FeeDetails
}

export class FeeHeadDrp extends FeeHeadObj {
    termsList: FeeTermDrp[]
}

export class PayableItem {
    studentFeeId: number;               
    paymentAmount: number;
    dueAmount: number;
    headName: string;
    termName: string;
}

export class PayableFee {
    invoiceNumber: string;
    controlNumber: string;
    paymentDate: string;
    paymentMethod: string;
    paymentDescription: string;
    checkNbr: string;
    paymentReferenceNbr: string;
    transactionStatus: string;
    clearanceStatus: string;
    paymentClearedDate: string;
    paymentSource: string;
    bankAccount: {
        bankAccountId: number;
    };
    student: {
        studentProfileId: number;   
    };
    paymentsList: PayableItem[];
}

export class DocumentTemplateSetup {
    id: number;
    category: string;
    templateName: string;
    displayName: string;
    isDefault: string;
    headerLine1: string;
    headerLine2: string;
    headerLine3: string;
    previewUrl: string;
}