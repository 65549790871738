import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { SPECTRUM_CONFIG } from './service';
import { AppConfig } from 'models';

@NgModule()
export class AppConfigModule {
    constructor(@Optional() @SkipSelf() parentModule: AppConfigModule) {
        if (parentModule) {
            throw new Error('AppConfigModule is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config: AppConfig): ModuleWithProviders<AppConfigModule> {
        return {
            ngModule: AppConfigModule,
            providers: [
                {
                    provide: SPECTRUM_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
