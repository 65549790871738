<div class="top-header-bg">
    <div class="container-fluid top-header">
        <div class="management-block">
            <a href="javascript:void(0);"><img class="logo" [src]="logoUrl" alt="Icon" /></a>
            <div class="text-white ml-2">
                {{schoolName ? schoolName : school}}
            </div>
            <div class="events">
                <a href="javascript:void(0);" (click)="showMenu = !showMenu" outSideClick (outsideClicked)="showMenu = false">
                    <img class="app-icon" *ngIf="app" [src]="app?.image" alt="Icon" />
                    <span>{{title ? title : app?.moduleName}}</span>
                    <img src="assets/icons/arrow_bottom.svg" alt="Icon" />
                </a>
                <div class="app-list" *ngIf="showMenu">
                    <div class="row mb-1 mx-0 border-bottom py-2">
                        <div class="col-12 d-flex align-items-center justify-content-between">
                            {{schoolName ? schoolName : school}}
                            <button type="button" class="app-btn outline" (click)="change()"><i class="fa fa-window-restore" aria-hidden="true"></i>&nbsp; Change</button>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-3" *ngFor="let item of apps">
                            <div class=" box">
                                <a href="javascript:void(0)" (click)="redirect(item)">
                                    <img [src]="item.image" class="img img-thumbnai" />
                                    {{item.moduleName}}
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <span dropdown class="position-relative">
            <a href dropdownToggle (click)="(false)" class="user-info_panel">
                <ul>
                    <li class="user-image">
                        <!-- <img *ngIf="!employeeImage" src="assets/images/user.jpg" alt="UserImage" /> -->
                        <img *ngIf="employeeImage" src="{{ employeeImage }}" alt="UserImage" />
                    </li>
                    <li class="user-name">
                        <span>{{employeeName}} </span>
                    </li>
                </ul>
                <img src="assets/icons/arrow_bottom.svg" alt="Icon" />
            </a>
            <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="User Menu">
                <ng-container *ngFor="let item of headerItems">
                    <li class="{{item.class}}" (click)="item.onTrigger()">
                        <i class="{{item.icon}}"></i>
                        <span>{{item.name}}</span>
                    </li>
                </ng-container>
            </ul>
        </span>
    </div>
</div>
