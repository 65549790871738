<div class="events" style="float: right; position: relative;">
    <button class="app-btn outline" (click)="showMenu = !showMenu" outSideClick (outsideClicked)="showMenu = false"> <i class="fa fa-cog"></i><span
        class="ml-1">Columns</span></button>

    <div class="app-list app-list-column" (click)="columnConfigClick($event)" *ngIf="showMenu">
        <div class="row mb-1 mx-0 border-bottom py-2">
            <div class="col-12 d-flex align-items-center justify-content-between">
                {{ selectedCount }} out of {{ columns?.length }} visible
                <button type="button" class="app-btn outline" (click)="reset()"><i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;Reset</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4 column" *ngFor="let c of columns">
                <input type="checkbox" [(ngModel)]="c.isVisible">&nbsp;{{ c.displayName }}
            </div>
        </div>
    </div>

</div>