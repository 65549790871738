import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, CommonUtility } from 'projects/common/src/public-api';
import { APIConstant } from '../constant';
import {
  Attendance,
  AttendanceReport,
  AttendanceReportRequest,
  BiometricConfig,
  ClassroomMapping,
  ClassroomRequest,
  Course,
  EmployeeMapping,
  MappedUnMappedResponse,
  Shift,
  StudentMapping
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(protected baseService: BaseService) { }

  getShifts(profileType: string, classroomIdList?: number): Observable<Shift[]> {
    let params = {};
    if (classroomIdList) {
      params = CommonUtility.convertObjectToParams({ profileType, classroomIdList });
    } else {
      params = CommonUtility.convertObjectToParams({ profileType });
    }
    return this.baseService.get<Shift[]>(`${APIConstant.shifts}?${params}`);
  }

  addShiftSetup(shift: Shift): Observable<Shift> {
    return this.baseService.post(APIConstant.shifts, shift);
  }

  updateShiftSetup(shift: Shift): Observable<Shift> {
    return this.baseService.put(`${APIConstant.shifts}/${shift?.shiftId}`, shift);
  }

  deleteShiftSetup(shiftId: number): Observable<Shift> {
    return this.baseService.delete(`${APIConstant.shifts}/${shiftId}`);
  }

  getClassroomsMappedToShift(shiftId: number): Observable<ClassroomMapping[]> {
    const params = CommonUtility.convertObjectToParams({ shiftId });
    return this.baseService.get<ClassroomMapping[]>(`${APIConstant.classrooms}?${params}`);
  }

  getStudentsMappedToShift(shiftId: number, classroomId: number): Observable<MappedUnMappedResponse<StudentMapping>> {
    const params = CommonUtility.convertObjectToParams({ shiftId, classroomId });
    return this.baseService.get<MappedUnMappedResponse<StudentMapping>>(`${APIConstant.students}?${params}`);
  }

  getEmployeesMappedToShift(shiftId: number): Observable<MappedUnMappedResponse<EmployeeMapping>> {
    const params = CommonUtility.convertObjectToParams({ shiftId });
    return this.baseService.get<MappedUnMappedResponse<EmployeeMapping>>(`${APIConstant.employees}?${params}`);
  }

  mapClassroomsToShift(shiftId: number, classRoomsIds: ClassroomRequest[]): Observable<MappedUnMappedResponse<Course>> {
    const url = APIConstant.mapClassrooms.replace('$shiftId', shiftId.toString());
    const params = CommonUtility.convertObjectToParams({ shiftId });
    return this.baseService.post(`${url}?${params}`, classRoomsIds);
  }

  updateClassroomsToShift(shiftId: number, classroom: ClassroomMapping): Observable<ClassroomMapping> {
    const url = APIConstant.mapClassrooms.replace('$shiftId', shiftId.toString());
    return this.baseService.put(url, classroom);
  }

  mapStudentsToShift(shiftId: number, classroomId: number, studentIds: number[]): Observable<MappedUnMappedResponse<StudentMapping>> {
    const url = APIConstant.mapStudents.replace('$shiftId', shiftId.toString());
    const params = CommonUtility.convertObjectToParams({ shiftId, classroomId });
    return this.baseService.post(`${url}?${params}`, studentIds);
  }

  mapEmployeesToShift(shiftId: number, employeesIds: number[]): Observable<MappedUnMappedResponse<EmployeeMapping>> {
    const url = APIConstant.mapEmployees.replace('$shiftId', shiftId.toString());
    return this.baseService.post(url, employeesIds);
  }

  deleteClassroomsToShift(shiftId: number, classroomId: number): Observable<ClassroomMapping> {
    const url = APIConstant.mapClassrooms.replace('$shiftId', shiftId.toString());
    const params = CommonUtility.convertObjectToParams({ shiftId, classroomId });
    return this.baseService.delete(`${url}?${params}`);
  }

  deleteEmployeesToShift(shiftId: number): Observable<EmployeeMapping> {
    const url = APIConstant.mapEmployees.replace('$shiftId', shiftId.toString());
    const params = CommonUtility.convertObjectToParams({ shiftId });
    return this.baseService.delete(`${url}?${params}`);
  }

  getStudentsAttendance(shiftId: number, classroomId: number, date: string): Observable<Attendance[]> {
    const params = CommonUtility.convertObjectToParams({ shiftId, classroomId, date });
    return this.baseService.get<Attendance[]>(`${APIConstant.studentAttendance}?${params}`);
  }

  getEmployeesAttendance(shiftId: number, date: string): Observable<Attendance[]> {
    const params = CommonUtility.convertObjectToParams({ shiftId, date });
    return this.baseService.get<Attendance[]>(`${APIConstant.employeeAttendance}?${params}`);
  }

  recordAttendance(profileType: any, attendance: Attendance[]): Observable<Attendance[]> {
    // const params = CommonUtility.convertObjectToParams({ profileType });
    return this.baseService.post(`${APIConstant.recordAttendance}${profileType}`, attendance);
  }

  getAttendanceReportByDateRange(request: AttendanceReportRequest): Observable<AttendanceReport[]> {
    const params = CommonUtility.convertObjectToParams(request);
    return this.baseService.get(`${APIConstant.report}?${params}`);
  }

  getBiometricPunchReport(data: any): Observable<Shift> {
    return this.baseService.post(APIConstant.punchReport, data);
  }

  getBiometricSetup(profileType: string): Observable<BiometricConfig[]> {
    const params = CommonUtility.convertObjectToParams({ profileType });
    return this.baseService.get<BiometricConfig[]>(`${APIConstant.biometricSetup}?${params}`);
  }

  updateBiometricSetup(profileType: string, configs: BiometricConfig[]): Observable<BiometricConfig[]> {
    const params = CommonUtility.convertObjectToParams({ profileType });
    return this.baseService.put<BiometricConfig[]>(`${APIConstant.biometricSetup}?${params}`, configs);
  }

  getStudentAttendanceSummary(classroomId) {
    return this.baseService.get<any>(`${APIConstant.studentSummary}?classroomId=${classroomId}`);
  }

  autoCalculateAttendance(data) {
    const params = CommonUtility.convertObjectToParams(data);
    return this.baseService.get<any>(`${APIConstant.autoCalculate}?${params}`);
  }

  saveAttendanceSummary(data) {
    return this.baseService.post<any>(`${APIConstant.saveAttedanceSummary}`, data);
  }
}
