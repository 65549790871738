import { Classroom } from "./classroom";

export class Course {
    courseId: number;
    courseName: string;
    deptId: number;
    displayOrder: number;
    isActive: boolean;
    classroomsList: Classroom[];
    departmentName:string;
    degreeName:string;
    degreeId:number;
    isSchool:boolean;
    masterName:string;
    constructor(obj: {
        courseName: string;
        masterName:string;
        displayOrder: number;
        isActive: boolean;
        classroomsList: Classroom[];
        courseId?: number;
        deptId?: number;
        departmentName:string;
        degreeName?:string;
        degreeId?:number;
        isSchool?:boolean;
    }) {
        this.courseId = obj.courseId || 0;
        this.deptId = obj.deptId || 0;
        this.courseName = obj.courseName || "";
        this.displayOrder = obj.displayOrder || 0;
        this.isActive = obj.isActive || false;
        this.classroomsList = obj.classroomsList || [];
        this.degreeName = obj.degreeName;
        this.degreeId = obj.degreeId;
        this.departmentName = obj.departmentName;
        this.isSchool=obj.isSchool || false;
        this.masterName=obj.masterName || "";
    }
}
