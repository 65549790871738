import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-single-preview',
    templateUrl: './single-preview.component.html',
    styleUrls: ['./single-preview.component.scss']
})
export class SinglePreviewComponent {

    @Input() templateData: any = {};
    @Output() selectedTemplate = new EventEmitter();
    @Output() download = new EventEmitter();

    constructor() { }

    onCardClick = () => {
        // this.templateData.forEach(item => item.active = false);
        this.templateData.active = true;
        this.selectedTemplate.emit({
            id: this.templateData.id,
            category: this.templateData.category,
            templateName: this.templateData.templateName,
            displayName: this.templateData.displayName,
            headerLine1: this.templateData.headerLine1,
            headerLine2: this.templateData.headerLine2,
            headerLine3: this.templateData.headerLine3,
            description: this.templateData.description,
            isDualTermReportCard: this.templateData.isDualTermReportCard
        });
    }
}