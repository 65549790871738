export class AuditFields {
  createdBy?: any;
  updatedBy?: any;
  createdTs: string;
  updatedTs: string;
}

export class APIRequest {
  libraryCode: string;
  loginUserFullName: string;
}

export class APIPagedResponse<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class Pageable {
  sort: Sort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  unpaged: boolean;
  paged: boolean;
}

export class Sort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export class Breadcrumb {
  text: string;
  route: string;
}

export class SearchParam {
  orderBy: string;
  direction: string;
  size: number;
  page: number;
}

export class Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  district: string;
  state: string;
  postalcode: string;
  country: string;
}

export class MappedUnMappedResponse<T> {
  Mapped: T[];
  UnMapped: T[];
}
