import { MemberBasicInfo } from './member';
import { AuditFields } from './common';
import { BookBasicInfo } from './book';

export class Transaction {
    id: number;
    issueDate: string;
    expectedReturnDate: string;
    actualReturnDate?: string;
    book: BookBasicInfo;
    member: MemberBasicInfo;
    remarks?: any;
    txnStatus: string;
    recordStatus: string;
    auditFields: AuditFields;
    libraryCode: string;
    // Index
    index: number;
}