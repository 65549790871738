import { Injectable } from '@angular/core';
import { TreeviewItem, TreeviewSelection, DefaultTreeviewI18n } from 'ngx-treeview';

@Injectable()
export class DropdownTreeviewSelectI18n extends DefaultTreeviewI18n {
    private internalSelectedItem: TreeviewItem;

    set selectedItem(value: TreeviewItem) {
        this.internalSelectedItem = value;
    }

    get selectedItem(): TreeviewItem {
        return this.internalSelectedItem;
    }

    // getText(selection: TreeviewSelection): string {
    //     return this.internalSelectedItem ? this.internalSelectedItem.text : 'Please select';
    // }

    getText(): string {
        if (this.internalSelectedItem) {
            console.log(this.internalSelectedItem)
            let result = '';
            if (this.internalSelectedItem.value.parent) {
                result += this.internalSelectedItem.value.parent;
                result += ' - ';
            }
            result += this.internalSelectedItem.value.text;
            return result;
        }
        return 'Please Select Term';
    }
}