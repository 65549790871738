import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { CommonConstant } from '../constants';

@Directive({
    selector: 'img[appImgFallback]'
})
export class ImageFallbackDirective {

    @Input()
    appImgFallback: string = CommonConstant.imageFallback;

    constructor(private eRef: ElementRef) { }

    @HostListener('error')
    loadFallbackOnError() {
        const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
        element.src = this.appImgFallback;
    }

}
