import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges } from '@angular/core';
import { TreeviewConfig, TreeviewItem, TreeviewI18n } from 'ngx-treeview';
import { LookupService } from '../../service';
import { Tree } from 'models';
import { CommonUtility } from '../../utilities';
import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
    selector: 'app-single-head',
    templateUrl: './single-head.component.html',
    styleUrls: ['single-head.component.scss'],
    providers: [
        { provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n }
    ]
})

export class SingleHeadComponent implements OnInit, OnChanges {

    @Input()
    params: number;

    @Input()
    value: number;

    @Input()
    data: any[] = null;

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter();

    @ViewChild('dropdown', { static: true }) dropdown: BsDropdownDirective;

    buttonLabel: string;
    // selectedItem : 

    config = TreeviewConfig.create({
        hasAllCheckBox: false,
        hasCollapseExpand: false,
        hasFilter: true,
        maxHeight: 350,

    });

    filterText: string;
    items: TreeviewItem[];
    private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;

    constructor(public i18n: TreeviewI18n, private lookupService: LookupService) {
        this.dropdownTreeviewSelectI18n = i18n as DropdownTreeviewSelectI18n;
    }

    ngOnInit() {
        this.getData();
    }

    ngOnChanges(): void {
        this.updateSelectedItem();
    }

    private getData() {
        if (!this.data) {
            this.lookupService.getFeeHeadsDropdown(this.params).subscribe(result => {
                this.items = this.structureTree(result);
                this.updateSelectedItem();
            })
        } else {
            this.items = this.structureTree(this.data);
            this.updateSelectedItem();
        }
    }

    // private structureTree(result: any[], textBy: string = 'headName', valueBy: string = 'feeHeadSetupId'): TreeviewItem[] {
    //     return result.map(item => {
    //         const parent = new TreeviewItem({
    //             text: item[textBy],
    //             value: item,
    //             children: []
    //         });
    //         if (item?.termsList?.length > 0) {
    //             parent.children = this.structureTree(item.termsList, 'termName', 'feeTermSetupId');
    //         }

    //         return parent;
    //     })
    // }

    private structureTree(result: Tree[]): TreeviewItem[] {
        return result.map(item => {
            const parent = new TreeviewItem({
                text: item.text ? item.text : '',
                value: item,
                children: []
            });
            if (item.children && item.children.length > 0) {
                parent.children = this.structureTree(item.children);
            }

            return parent;
        })
    }

    select(item: TreeviewItem): void {
        if (!item.children) {
            this.selectItem(item);
        }
    }

    private updateSelectedItem(): void {
        if (CommonUtility.isNotNull(this.items)) {
            const selectedItem = CommonUtility.findItemInList(this.items, Number(this.value));
            this.selectItem(selectedItem);
        }
    }

    selectItem(item: TreeviewItem): void {
        if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
            this.dropdownTreeviewSelectI18n.selectedItem = item;
            if (item) {
                if (this.value !== item.value) {
                    this.value = item.value;
                    this.valueChange.emit(item.value);
                }
            }
        }
        if (this.dropdown) {
            this.dropdown.hide();
        }
        this.buttonLabel = this.dropdownTreeviewSelectI18n.getText();
    }
}
