export class NewSchools {
      isSchool: boolean;
    constructor(obj: {
        district: string;
        ecomschoolId:string;
        educationBoard:string;
        emailAddress1: string;
        emailAddress2: string;
        groupCd: string;
        instituteType: string;
        isActive: string;
        isCentralOffice: string;
        location: string;
        logoLocation: string;
        phoneNumber1: string;
        phoneNumber2: string;
        postalCd: string;
        profileType: string;
        schoolCd: string;
        schoolId: number;
        schoolName: string;
        state: string;
        streetAddress: string;
        subscriptionAmount: string;
    }){

    }
}
