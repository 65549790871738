import { ProfileType } from './enum/ProfileType';
import { Classroom } from './classroom';

export class Shift {
    actualCount: number
    classroomList: Classroom[]
    emploeeList: any[]
    endTime: string
    mappedCount: number
    name: string
    profileType: ProfileType
    schoolCd: string
    shiftId: number
    startTime: string
    graceTime: number
    isActive: string
    punchStartTime: string
}

export class ShiftMapping {
    id: number;
    deviceProfileId: number;
    shiftSetup: Shift 
}