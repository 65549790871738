import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ThemeHelpersService } from "../../service";
import { CommonUtility } from "../../utilities";

@Component({
    selector: "app-column-config",
    templateUrl: "./column-config.component.html",
    styleUrls: ["column-config.component.scss"],
})
export class ColumnConfigComponent implements OnInit {

    @Input()
    tableName: string;
    @Input()
    type: string;
    @Output()
    setColumnConfig: EventEmitter<any> = new EventEmitter();
    showMenu = false;
    columns: any[] = [];

    constructor(
        private themeHelper: ThemeHelpersService
    ) {
        this.themeHelper.listen().subscribe(data => {
            if (data[0] === 'datatable:update') {
                this.columns = data[1];
                this.columnConfig();
            }
        })
    }

    ngOnInit() {
        this.columnConfig();
    }

    get selectedCount() {
        let count = 0;
        if (this.columns && this.columns.length > 0) {
            for (const column of this.columns) {
                if (column.isVisible) {
                    count += 1;
                }
            }
        }
        return count;
    }

    private async columnConfig() {
        let datatable = CommonUtility.datatable;
        console.log('datatable', datatable)
        if (!datatable) {
            datatable = null;
            datatable = JSON.parse(sessionStorage.getItem('datatable'));
        }
        if (datatable && Object.keys(datatable).length === 0 && datatable.constructor === Object) {
            datatable = null;
        }

        if (!datatable) {
            console.log(`Making Delay`);
            await CommonUtility.delay(500);
            //this.ngOnInit();
            if (!datatable) {
                this.columnConfig();
                return;
            }

        }

        console.log('datatable utils', datatable);
        if (datatable) {
            const tables = datatable;
            if (tables && tables[this.tableName]) {
                this.columns = tables[this.tableName];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['STUDENT_SEARCH'] && this.type == 'STUDENT_SEARCH') {
                this.columns = tables['STUDENT_SEARCH'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['BIRTHDAY_REPORT'] && this.type == 'BIRTHDAY_REPORT') {
                this.columns = tables['BIRTHDAY_REPORT'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['STUDENT_DROPOUT_REPORT'] && this.type == 'STUDENT_DROPOUT_REPORT') {
                this.columns = tables['STUDENT_DROPOUT_REPORT'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['VIEW_ALL_STUDENT_REPORT'] && this.type == 'VIEW_ALL_STUDENT_REPORT') {
                this.columns = tables['VIEW_ALL_STUDENT_REPORT'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['EMPLYEE_SEARCH'] && this.type == 'EMPLYEE_SEARCH') {
                this.columns = tables['EMPLYEE_SEARCH'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['FEE_HEAD_WISE_REPORT'] && this.type == 'FEE_HEAD_WISE_REPORT') {
                this.columns = tables['FEE_HEAD_WISE_REPORT'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['FEE_PAYMENTS_BY_STATUS'] && this.type == 'FEE_PAYMENTS_BY_STATUS') {
                this.columns = tables['FEE_PAYMENTS_BY_STATUS'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['STUDENT_LIST'] && this.type == 'STUDENT_LIST') {
                this.columns = tables['STUDENT_LIST'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['TRIP_WISE_STUDENT_REPORT'] && this.type == 'TRIP_WISE_STUDENT_REPORT') {
                this.columns = tables['TRIP_WISE_STUDENT_REPORT'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            } else if (tables && tables['STOP_WISE_STUDENT_REPORT'] && this.type == 'STOP_WISE_STUDENT_REPORT') {
                this.columns = tables['STOP_WISE_STUDENT_REPORT'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            }
            else if (tables && tables['STUDENT_TRANSPORT_FEE_DUE'] && this.type == 'STUDENT_TRANSPORT_FEE_DUE') {
                this.columns = tables['STUDENT_TRANSPORT_FEE_DUE'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            }
            else if (tables && tables['ASSIGNMENT_WISE_REPORT'] && this.type == 'ASSIGNMENT_WISE_REPORT') {
                this.columns = tables['ASSIGNMENT_WISE_REPORT'];
                if (this.columns && this.columns.length > 0) {
                    for (const c of this.columns) {
                        if (c.isDefault === 'Y') {
                            c.isVisible = true;
                        }
                    }
                }
            }
        }
        this.setColumnConfig.emit(this.columns);
    }

    columnConfigClick(e) {
        e.stopPropagation();
    }

    reset() {
        if (this.columns && this.columns.length > 0) {
            for (const column of this.columns) {
                if (column.isDefault === 'Y') {
                    column.isVisible = true;
                } else {
                    column.isVisible = false;
                }
            }
        }
    }
}
