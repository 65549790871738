import { FormControl } from "@angular/forms";

export class ValidationService {
    static getValidatorErrorMessage(
        validatorName: string,
        validatorValue?: any,
        message?: string
    ) {
        let config = {
            required: `*${message} is required`,
            invalidEmailAddress: "Invalid email address",
            minlength: `Minimum length ${validatorValue.requiredLength}`,
            mismatchedPassword: "Password is not matching",
            onlyNumber: "Only Numbers allowed",
            min: `Minimum value ${validatorValue.min}`,
            max: `Maximum value ${validatorValue.max}`,
            minSelection: `Atleast select  ${validatorValue.min} ${message}`,
            invalidPhoneNumber: " Enter 10 digit number",
            invalidZipcode: " Enter valid zipcode",
            fixedLength: `Enter ${validatorValue.min} ${message}`,
            invalidaadharCard: `Enter valid Aadhar Card`,
        };
        return config[validatorName];
    }

    static emailValidator(control) {
        // RFC 2822 compliant regex
        if (
            !control.value ||
            control.value.match(
                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
            )
        ) {
            return null;
        } else {
            return { invalidEmailAddress: true };
        }
    }

    static compareNewPassword(control) {
        if (!control.root || !control.root.controls) {
            return null;
        }
        const exactMatch =
            control.root.controls.newPassword.value === control.value;
        return exactMatch ? null : { mismatchedPassword: true };
    }

    static comparePassword(control) {
        if (!control.root || !control.root.controls) {
            return null;
        }
        const exactMatch =
            control.root.controls.password.value === control.value;
        return exactMatch ? null : { mismatchedPassword: true };
    }

    static allowOnlyNumber(control) {
        if (control.value) {
            let tokenValue: number = control.value.toString().trim();
            if (!isNaN(tokenValue)) {
                return null;
            }
        }
        return { onlyNumber: true };
    }

    static minSelectedCheckboxes(min: number) {
        return (control: FormControl) => {
            const totalSelected = (control.value as any[]).filter((x) => x);

            return totalSelected.length >= min
                ? null
                : { minSelection: { valid: false, min: min } };
        };
    }

    static phoneNoValidator(control) {
        if (!control.value || String(control.value).match(/^\d{10}$/)) {
            return null;
        } else {
            return { invalidPhoneNumber: true };
        }
    }

    static zipcodeValidator(control) {
        if (!control.value || control.value.match(/^\d{6}$/)) {
            return null;
        } else {
            return { invalidZipCode: true };
        }
    }

    static fixedLengthValidator(length: number) {
        return (control: FormControl) => {
            if (!control.value || control.value.length === length) {
                return null;
            } else {
                return { fixedLength: true };
            }
        };
    }

    static aadharCardValidator(control) {
        if (!control.value || control.value.match(/^\d{12}$/)) {
            return null;
        } else {
            return { invalidaadharCard: true };
        }
    }
}
