import { StudentMapping } from './student';

export class Classroom {
    classroomId: number;
    classroomName: string;
    courseId: number;
    courseName: string;
    degreeId: number;
    degreeName: string;
    deptId: number;
    deptName: string;
    displayOrder: number;
    isActive: boolean;
    constructor(obj: {
        classroomName: string;
        courseName: string;
        degreeName: string;
        deptName: string;
        displayOrder: number;
        isActive: boolean;
        degreeId?: number;
        deptId?: number;
        courseId?: number;
        classroomId?: number;
    }) {
        this.degreeId = obj.degreeId || 0;
        this.deptId = obj.deptId || 0;
        this.courseId = obj.courseId || 0;
        this.classroomId = obj.classroomId || 0;
        this.degreeName = obj.degreeName || "";
        this.deptName = obj.deptName || "";
        this.courseName = obj.courseName || "";
        this.classroomName = obj.classroomName || "";
        this.displayOrder = obj.displayOrder || 0;
        this.isActive = obj.isActive || false;
    }
}

export class ClassroomMapping {
    classroomId: number;
    classroomName: string;
    courseName: string;
    degreeName: string;
    deptName: string;
    displayOrder: number;
    mappedStudentCount: number;
    assignmentType: 'Partial' | 'Full';
    shiftId: number;
}

export class ClassroomRequest {
    shiftId: number;
    classroomId: number;
    mappedStudentCount: number;
    assignmentType: 'Partial' | 'Full';
}
