import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,

    school: `${apiPath}/school`,
    profileType: `${apiPath}/school/profile-type`,
    classrooms: `${apiPath}/school/classrooms`,
    allClassrooms: `${apiPath}/school/classrooms/all`,
    courses: `${apiPath}/school/courses`,
    degrees: `${apiPath}/school/degrees`,
    departments: `${apiPath}/school/departments`,
    schoolProfiles: `${apiPath}/schoolProfiles`,
    currentAcademicYear: `${apiPath}/academics/academic-year-setup/current-academic-year`,
    copyFeeFromRefClassroom: `${apiPath}/finance/fee-setup/copy-from-ref-classroom`,
    copyShiftFromRefClassroom: `${apiPath}/attendance/shifts/copy-from-ref-classroom`,
    copySubjectFromRefClassroom: `${apiPath}/academics/subjects/byClassroom/copy-from-ref-classroom`,
    copySkillFromRefClassroom: `${apiPath}/academics/assessments/skills/copy-from-ref-classroom`,
    copyExamFromRefClassroom: `${apiPath}/academics/exams/copy-from-ref-classroom`,
    copyReportCardFromRefClassroom: `${apiPath}/academics/reportCard/setup/copy-from-ref-classroom`,
    defaultCourses: `${apiPath}/school/courses/default`,
};

export const PublicAPI = [];
