<div class="modal-header">
    <h4 class="modal-title pull-left">{{displayName}} Lookup Management</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th class="w-25">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of lookupData">
                            <td>
                                <span *ngIf="!item.editMode">{{item.value}}</span>
                                <input class="form-control" *ngIf="item.editMode" [(ngModel)]="item.newValue" placeholder="Enter value" />
                            </td>
                            <td>
                                <ng-container *ngIf="!item.editMode">
                                    <button class="btn btn-sm btn-outline-primary" (click)="edit(item)">
                                        <i class="fa fa-pencil" aria-hidden="true" title="Edit"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-danger" (click)="delete(item)">
                                        <i class="fa fa-trash-o" aria-hidden="true" title="Delete"></i>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="item.editMode">
                                    <button class="btn btn-sm btn-outline-primary" [disabled]="!item.newValue" (click)="update(item)">
                                        <i class="fa fa-floppy-o" aria-hidden="true" title="Update"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-danger" (click)="cancel(item)">
                                        <i class="fa fa-times" aria-hidden="true" title="Cancel"></i>
                                    </button>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <button class="btn btn-sm btn-outline-primary mt-1" type="button" *ngIf="!isAdd" (click)="isAdd = true">
                                <i class="fa fa-plus" aria-hidden="true" title="Add"></i>
                            </button>
                            <td *ngIf="isAdd">
                                <input class="form-control" [(ngModel)]="addValue" placeholder="Enter value" />
                            </td>
                            <td *ngIf="isAdd">
                                <ng-container>
                                    <button class="btn btn-sm btn-outline-primary" [disabled]="!addValue" (click)="add(addValue)">
                                        <i class="fa fa-floppy-o" aria-hidden="true" title="Add"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-danger" (click)="cancelAdd()">
                                        <i class="fa fa-times" aria-hidden="true" title="Cancel"></i>
                                    </button>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="app-btn" (click)="close()">Done</button>
</div>
