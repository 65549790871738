
import { Injectable } from "@angular/core";
import { CommonUtility } from "../utilities";
import { CommonConstant } from '../constants';
import { User } from 'models';

@Injectable({
    providedIn: "root"
})
export class UserAuthService {

    constructor() {
    }

    saveToken(token: string) {
        window.sessionStorage.setItem(CommonConstant.token, token);
    }

    saveGuestToken(token: string) {
        window.sessionStorage.setItem(CommonConstant.guestToken, token);
    }

    saveUser(user: User) {
        window.sessionStorage.setItem(CommonConstant.user, JSON.stringify(user));
    }

    saveOrgCode(orgCode: string) {
        window.sessionStorage.setItem(CommonConstant.orgCode, orgCode);
    }

    saveSchoolName(schoolName: string) {
        window.sessionStorage.setItem(CommonConstant.school, schoolName);
    }

    saveEmployeeName(employeeName: string){
        window.sessionStorage.setItem(CommonConstant.employeeName, employeeName);
    }

    saveStudentName(studentName: string){
        window.sessionStorage.setItem(CommonConstant.studentName, studentName);
    }

    saveEmployeeId(employeeId: any){
        window.sessionStorage.setItem(CommonConstant.employeeId, employeeId);
    }

    saveStudentProfileId(studentId: any){
        window.sessionStorage.setItem(CommonConstant.studentProfileId, studentId);
    }

    saveAppName(appName: string) {
        window.sessionStorage.setItem(CommonConstant.appName, appName);
    }


    getToken(): string {
        let token = window.sessionStorage.getItem(CommonConstant.token);
        if(!token){
            token = this.getTokenFromUrl();
        }
        return token
    }

    getTokenFromUrl(){
        console.log('Called before save')
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        return token;
    }

    getOrgCodes(): string {
        let orgCode =  window.sessionStorage.getItem(CommonConstant.orgCode);
        if(!orgCode){
            orgCode = this.getOrgCodesFromUrl();
        }
        return orgCode;
    }

    getSchoolName(): string {
        return window.sessionStorage.getItem(CommonConstant.school);
    }

    getEmployeeName(): string {
        return window.sessionStorage.getItem(CommonConstant.employeeName);
    }

    getEmployeeId(): any {
        return window.sessionStorage.getItem(CommonConstant.employeeId);
    }

    getOrgCodesFromUrl(): string {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        return code;
    }

    getUser(): User {
        const user = window.sessionStorage.getItem(CommonConstant.user);
        if (!CommonUtility.isEmpty(user)) {
            return JSON.parse(user);
        }
        return null;
    }

    isLoggedIn(): boolean {
        return !CommonUtility.isNull(this.getToken());
    }

    logout(): void {
        window.sessionStorage.clear();
    }
    getStudentName(): string {
        return window.sessionStorage.getItem(CommonConstant.studentName);
    }

    getStudentProfileId(){
      return   window.sessionStorage.getItem(CommonConstant.studentProfileId);
    }

    getAppName() {
     return    window.sessionStorage.getItem(CommonConstant.appName);
    }

    getUserName() {
      return   window.sessionStorage.getItem('userName');
    }

    saveUserName(userName): void {
        window.sessionStorage.setItem(CommonConstant.userName, userName);
    }



}
