import {
    Directive, ElementRef, Input, HostListener, Output, EventEmitter
} from '@angular/core';

@Directive({ selector: '[outSideClick]' })
export class OutsideClickDirective {

    @Input()
    allowElement: string;

    @Output()
    outsideClicked: EventEmitter<void> = new EventEmitter();

    constructor(private el: ElementRef) { }


    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (!this.el.nativeElement.contains(event.target)) {
            this.outsideClicked.emit();
        }

    }

}
